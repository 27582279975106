import { createAction } from "redux-actions";
import { api } from "../utils/api";

export const branchesActions = {
  GET_ALL_BRANCHS: createAction("GET_ALL_BRANCHS"),
  GET_ALL_BRANCHS_SUCCESS: createAction("GET_ALL_BRANCHS_SUCCESS", (data) => {
    return data;
  }),
  GET_ALL_BRANCHS_ERROR: createAction("GET_ALL_BRANCHS_ERROR", (error) => {
    return {
      error,
    };
  }),
  GET_TOTAL_USERS: createAction("GET_TOTAL_USERS"),
  GET_TOTAL_USERS_SUCCESS: createAction("GET_TOTAL_USERS_SUCCESS", (data) => {
    return data;
  }),
  GET_TOTAL_USERS_ERROR: createAction("GET_TOTAL_USERS_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getAllBranchesAction = () => (dispatch) => {
  dispatch(branchesActions.GET_ALL_BRANCHS());
  api
    .get("/get_branch/token")
    .then((res) => {
      dispatch(branchesActions.GET_ALL_BRANCHS_SUCCESS(res.data.data));
    })
    .catch((error) => dispatch(branchesActions.GET_ALL_BRANCHS_ERROR(error)));
};

export const getTotalUsersAction = () => (dispatch) => {
  dispatch(branchesActions.GET_TOTAL_USERS());
  api
    .get("/get_total_users")
    .then((res) => {
      dispatch(branchesActions.GET_TOTAL_USERS_SUCCESS(res.data.data));
    })
    .catch((error) => dispatch(branchesActions.GET_TOTAL_USERS_ERROR(error)));
};
