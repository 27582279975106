import { store } from "../index";
import { logoutUserAction } from "../actions/authActions";

const unauthorizedError = (error, history) => {
  if (
    error?.payload?.response?.status === 403 ||
    error?.payload?.response?.status === 401
  ) {
    store.dispatch(logoutUserAction());
    return true;
  } else {
    return false;
  }
};

export default unauthorizedError;
