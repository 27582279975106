import { createAction } from "redux-actions";
import { api } from "../utils/api";
import { authHeader } from "../utils/authHeader";

export const vehiclesTypesActions = {
  GET_TYPES: createAction("GET_TYPES"),
  GET_TYPES_SUCCESS: createAction("GET_TYPES_SUCCESS", (data) => {
    return data;
  }),
  GET_TYPES_ERROR: createAction("GET_TYPES_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getTypesAction = () => (dispatch) => {
  dispatch(vehiclesTypesActions.GET_TYPES());
  let lang = localStorage.getItem("i18nextLng").split("-")[0];
  if (!lang) {
    lang = "ES";
  }
  api
    .get("/get_types/" + authHeader() + "/" + lang)
    .then((res) => {
      dispatch(vehiclesTypesActions.GET_TYPES_SUCCESS(res.data.data));
    })
    .catch((error) => dispatch(vehiclesTypesActions.GET_TYPES_ERROR(error)));
};
