import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Styles
import "./Footer.css";

// Assets
import logo from "../../assets/images/logo.svg";
import footerIcon from "./assets/icons/footer.svg";

export default function Footer(props) {
  const { t } = useTranslation();

  return (
    <footer className="mdq-footer">
      <div className="mdq-footer-content">
        <div className="mdq-footer-content-item">
          <img src={logo} alt="logo" className="mdq-footer-content-logo" />
        </div>
        <div className="mdq-footer-content-item">
          <div className="mdq-button-wrapper">
            <NavLink className="default-button" to="/reseller">
              <span>{t("footer.reseller-button")}</span>
            </NavLink>
          </div>
        </div>
        <div className="mdq-footer-content-item">
          <div className="mdq-footer-contact">
            <img className="mdq-footer-icon" src={footerIcon} alt="" />
            <div className="mdq-footer-cl">
              <p className="mdq-footer-title">
                {t("footer.sales-support.title")}
              </p>
              <p className="mdq-footer-text">
                {t("footer.sales-support.data")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
