import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../utils/scrollToTop";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAction, reset } from "../../actions/authActions";
import { IconButton, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import Signin from "./components/Signin/Signin";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./Login.css";

// Assets
import image from "./assets/images/background.jpg";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Login(props) {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState();
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.authReducer.fetching);
  const res = useSelector((state) => state.authReducer.data);
  const [message, setMessage] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleClickAlertMessage = (newMessage) => {
    setMessage(newMessage);
    setOpenAlertMessage(true);
  };

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertMessage(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUserAction(email, values.password));
  };

  useEffect(() => {
    if (localStorage.getItem("token_credentials")) {
      if (res && res.ok) {
        dispatch(reset());
      }
      if (props.location.state) {
        history.push(props.location.state.from.pathname);
      } else {
        history.push("/account");
      }
    } else {
      document.body.style.backgroundImage = "none";
      document.body.style.backgroundColor = "transparent";
      if (fetching) {
        handleToggleBackdrop();
      } else {
        handleCloseBackdrop();
        if (res && res.message) {
          handleClickAlertMessage(res.message);
          dispatch(reset());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching, res, props]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <section className="login">
        <div
          className="login-sections"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="login-sections-header">
            <MdqLogo />
          </div>
          <div className="login-sections-content">
            <Signin />
          </div>
        </div>
        <div className="login-content-wrapper">
          <ValidatorForm
            className="login-form"
            onSubmit={handleSubmit}
            instantValidate={false}
          >
            <div className="login-form-inputs">
              <TextValidator
                className="login-form-item login-form-item-email"
                label={t("login.email.label")}
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                validators={["required", "isEmail"]}
                errorMessages={[
                  t("input-error-messages.required"),
                  t("input-error-messages.is-email"),
                ]}
              />
              <TextValidator
                className="login-form-item"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                label={t("login.password.label")}
                name="password"
                onChange={(e) =>
                  setValues({ ...values, password: e.target.value })
                }
                validators={["required", "minStringLength: 4"]}
                errorMessages={[
                  t("input-error-messages.required"),
                  t("input-error-messages.min-string-length"),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="default-button-container talk-to-us-button-wrapper">
              <button type="submit" className="default-button">
                <span>{t("login.submit-button")}</span>
              </button>
            </div>
          </ValidatorForm>
          <ResetPassword />
        </div>
      </section>
      <AlertMessage
        open={openAlertMessage}
        handleClose={handleCloseAlertMessage}
        alertMessage={message}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

export default Login;
