import React from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../../../../utils/scrollToTop";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../../../components/Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import MdqLogo from "../../../../components/MdqLogo/MdqLogo";
import AlientechLogo from "../../../../components/AlientechLogo/AlientechLogo";

// Styles
import "../../Home.css";
import "./Home01.css";

// Assets
import image1 from "./assets/1.jpg";
import image2 from "./assets/2.jpg";
import image3 from "./assets/3.jpg";
import image4 from "./assets/4.jpg";
import arrow from "../../../../assets/icons/arrow.svg";

const sliderImages = [image1, image2, image3, image4];

function Home01() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        stopOnHover={false}
        autoPlay={true}
        infiniteLoop={true}
        className="home-01-carousel"
      >
        {sliderImages.map((slide, index) => (
          <div
            className="home-01-carousel-item"
            key={index}
            style={{
              backgroundImage: `url(${slide})`,
              backgroundSize: "cover",
            }}
          />
        ))}
      </Carousel>
      <div className="topbar-overlay" />
      <Navbar />
      <Sidebar section={"1"} />
      <section className="home-section">
        <MdqLogo />
        <div className="home-slider-container">
          <div className="home-slider-container-row1">
            <div>
              <h2 className="home-slider-title title-01">
                {t("home01.title")}
              </h2>
              <p className="home-slider-subtitle subtitle-01">
                {t("home01.subtitle")}
              </p>
            </div>
            <div className="home-button-wrapper">
              <NavLink className="default-button" to="/vehicle-search">
                <span>{t("home01.default-button")}</span>
              </NavLink>
            </div>
          </div>
          <div className="home-slider-container-row2">
            <div className="home-slider-container-row2-cl-start">
              <AlientechLogo />
            </div>
            <div className="home-slider-container-row2-cl-center">
              <NavLink className="arrow-down-button" to="/home/2">
                <img src={arrow} alt="" className="arrow-down" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </React.Fragment>
  );
}

export default Home01;
