import { handleActions } from "redux-actions";

export const turnsReducer = handleActions(
  {
    GET_ALL_TURNS: (state, action) => {
      return {
        ...state,
        turnsError: null,
        fetching: true,
      };
    },
    GET_ALL_TURNS_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        turns: action.payload.data,
      };
    },
    GET_ALL_TURNS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        turnsError: action,
      };
    },
    SET_TURN: (state, action) => {
      return {
        ...state,
        turnsError: null,
        fetching: true,
      };
    },
    SET_TURN_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    SET_TURN_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        turnsError: action,
      };
    },
    TAKE_TURN: (state, action) => {
      return {
        ...state,
        turnsError: null,
        fetchingTakeTurn: true,
      };
    },
    TAKE_TURN_SUCCESS: (state, action) => {
      return {
        ...state,
        fetchingTakeTurn: false,
        takeTurn: action.payload,
        turns: state.turns.map((turn) => {
          if (turn.id === action.payload.id) {
            turn.taken = "1";
          }
          return turn;
        }),
      };
    },
    TAKE_TURN_ERROR: (state, action) => {
      return {
        ...state,
        fetchingTakeTurn: false,
        turnsError: action,
      };
    },
    RESET_TAKE_TURN: (state, action) => {
      return {
        ...state,
        takeTurn: false,
      };
    },
    RESET: (state, action) => {
      return {
        fetching: false,
        data: action.payload,
        turnsError: null,
        turns: action.payload,
      };
    },
  },
  {
    fetching: false,
    turnsError: null,
    data: [],
    turns: false,
    fetchingTakeTurn: false,
    takeTurn: false,
  }
);
