import { createAction } from "redux-actions";
import { api } from "../utils/api";
import { authHeader } from "../utils/authHeader";

export const turnsActions = {
  GET_ALL_TURNS: createAction("GET_ALL_TURNS"),
  GET_ALL_TURNS_SUCCESS: createAction("GET_ALL_TURNS_SUCCESS", (data) => {
    return data;
  }),
  GET_ALL_TURNS_ERROR: createAction("GET_ALL_TURNS_ERROR", (error) => {
    return {
      error,
    };
  }),
  SET_TURN: createAction("SET_TURN"),
  SET_TURN_SUCCESS: createAction("SET_TURN_SUCCESS", (data) => {
    return data;
  }),
  SET_TURN_ERROR: createAction("SET_TURN_ERROR", (error) => {
    return {
      error,
    };
  }),
  TAKE_TURN: createAction("TAKE_TURN"),
  TAKE_TURN_SUCCESS: createAction("TAKE_TURN_SUCCESS", (data) => {
    return data;
  }),
  TAKE_TURN_ERROR: createAction("TAKE_TURN_ERROR", (error) => {
    return {
      error,
    };
  }),
  RESET_TAKE_TURN: createAction("RESET_TAKE_TURN", () => {
    return null;
  }),
  RESET: createAction("RESET", () => {
    return null;
  }),
};

export const reset = () => (dispatch) => {
  dispatch(turnsActions.RESET());
};

export const resetTakeTurn = () => (dispatch) => {
  dispatch(turnsActions.RESET_TAKE_TURN());
};

export const setTurnAction = (
  date,
  branchId,
  userId,
  vehicleId,
  vehicleDomain
) => (dispatch) => {
  dispatch(turnsActions.SET_TURN());
  var FormData = require("form-data");
  let params = new FormData();
  params.append(
    "datetime",
    date.toISOString().split("T")[0] + " " + date.toTimeString().split(" ")[0]
  );
  params.append("branch_id", branchId);
  params.append("client_id", userId);
  params.append("vehicle_id", vehicleId);
  params.append("vehicle_domain", vehicleDomain);
  api
    .post("/set_turn/" + authHeader(), params)
    .then((res) => {
      dispatch(turnsActions.SET_TURN_SUCCESS(res.data));
    })
    .catch((error) => dispatch(turnsActions.SET_TURN_ERROR(error)));
};

export const getAllTurnsAction = (userId, branchId) => (dispatch) => {
  dispatch(turnsActions.GET_ALL_TURNS());
  var FormData = require("form-data");
  let params = new FormData();
  if (userId) {
    params.append("client_id", userId);
  } else if (branchId) {
    params.append("branch_id", branchId);
  }
  api
    .post("/get_turn/" + authHeader(), params)
    .then((res) => {
      res.data.data.forEach((res) => {
        res.dateDay = res.datetime.split(" ")[0].split("-")[2];
        res.dateMonth = res.datetime.split(" ")[0].split("-")[1];
        res.dateYear = res.datetime.split(" ")[0].split("-")[0];
        res.timeHour = res.datetime.split(" ")[1].split(":")[0];
        res.timeMinutes = res.datetime.split(" ")[1].split(":")[1];
      });
      dispatch(turnsActions.GET_ALL_TURNS_SUCCESS(res.data));
    })
    .catch((error) => dispatch(turnsActions.GET_ALL_TURNS_ERROR(error)));
};

export const takeTurnAction = (turnId) => (dispatch) => {
  dispatch(turnsActions.TAKE_TURN());
  api
    .get("/take_turn/" + authHeader() + "/" + turnId + "/1")
    .then((res) => {
      res.data.id = turnId;
      dispatch(turnsActions.TAKE_TURN_SUCCESS(res.data));
    })
    .catch((error) => dispatch(turnsActions.TAKE_TURN_ERROR(error)));
};
