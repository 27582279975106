import React, { useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import ScrollToTop from "../../utils/scrollToTop";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import TeamSectionCards from "./components/TeamSectionCards/TeamSectionCards";
import Footer from "../../components/Footer/Footer";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./We.css";

// Assets
import arrowIcon from "../../assets/icons/arrow.svg";
import background from "./assets/images/2.jpg";
import car from "./assets/images/car.png";
import bgLines from "./assets/images/bg-lines.svg";
import image0 from "./assets/team/0.png";
import image2 from "./assets/team/2.png";
import image4 from "./assets/team/4.png";
import image7 from "./assets/team/7.png";
import image8 from "./assets/team/8.png";
import image9 from "./assets/team/9.png";
import image10 from "./assets/team/10.png";

function We(props) {
  const weSection = useRef(null);
  
  const { t } = useTranslation();

  const items = [
    {
      id: 0,
      section: t("we.team-sections.0"),
      team: [
        {
          id: 0,
          name: "Daniel Salerno",
          position: "Chief Operating Officer / COO",
          tel: "",
          email: "",
          image: image0,
        },
        {
          id: 2,
          name: t("we.engineer-abbreviation") + " Juan Terranova",
          position: "Latin America Chief Executive Officer / L.A CEO",
          tel: "",
          email: "juan@mdqracing.com",
          image: image2,
        },
      ],
      color: "grey",
    },
    {
      id: 1,
      section: t("we.team-sections.1"),
      team: [
        {
          id: 3,
          name: "Sergio Rodrieguez",
          position: "Academy & Technical Support Manager / TSM",
          tel: "",
          email: "sergiordz@mdqracing.com",
          image: image10,          
        },
        {
          id: 4,
          name: "Matias Lovizzio",
          position: "Sales Manager / SM",
          tel: "0810 220 MDQR(6377) - Int: 113",
          email: "matias@mdqracing.com",
          image: image4,
        },
        {
          id: 9,
          name: "Gastón Parulis",
          position: "Sales manager Spain ",
          tel: "+34 951 402 860",
          email: "parulis@mdqracing.com",
          image: image9,
        },
      ],
      color: "red",
    },
    /* {
      id: 1,
      section: t("we.team-sections.4"),
      team: [
        {
          id: 10,
          name: "Sergio Rodrieguez",
          position: "Alientech Academy",
          tel: "",
          email: "sergiordz@mdqracing.com",
          image: image10,
        },
      ],
      color: "red",
    }, */
    {
      id: 2,
      section: t("we.team-sections.2"),
      team: [
        {
          id: 7,
          name: "Sebastian Salerno",
          position: "Chief Technology Officer / CTO",
          tel: "",
          email: "sebastian@mdqracing.com",
          image: image7,
        },
      ],
      color: "rgba(255, 183, 0, 0.755)",
    },
    {
      id: 3,
      section: t("we.team-sections.3"),
      team: [
        {
          id: 8,
          name: "María Cristina Burgos",
          position: "Administrative Officer / AO",
          tel: "",
          email: "cristina@mdqracing.com",
          image: image8,
        },
      ],
      color: "rgba(255, 68, 0, 0.755)",
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    document.documentElement.style.overflow = "auto";
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <MdqLogo />
      <section className="we-section" ref={weSection}>
        <div className="we-container">
          <div className="we-container-row">
            <h2 className="we-section-title">
              {t("we.title1") + " "}
              <span style={{ color: "red" }}>{t("we.title2")}</span>
            </h2>
            <p className="we-section-subtitle">
              {t("we.subtitle1")}
              <br /> {t("we.subtitle2")}
            </p>
          </div>
          <Link className="we-team-to-team-button" smooth to="#team">
            <img src={arrowIcon} className="we-team-to-team" alt="" />
          </Link>
        </div>
        <div id="team" className="we-team-container">
          <img src={bgLines} className="we-team-container-bg-lines" alt="" />
          {items.map((e, index) => (
            <TeamSectionCards
              name={e.section}
              team={e.team}
              color={e.color}
              key={index}
            />
          ))}
          <img src={car} className="we-team-car" alt="" />
        </div>
        <Footer />
      </section>
    </React.Fragment>
  );
}

export default We;
