import { createAction } from "redux-actions";
import { api } from "../utils/api";
import { authHeader } from "../utils/authHeader";

export const vehiclesActions = {
  GET_VEHICLES: createAction("GET_VEHICLES"),
  GET_VEHICLES_SUCCESS: createAction("GET_VEHICLES_SUCCESS", (data) => {
    return data;
  }),
  GET_VEHICLES_ERROR: createAction("GET_VEHICLES_ERROR", (error) => {
    return {
      error,
    };
  }),
  GET_SERVICES: createAction("GET_SERVICES"),
  GET_SERVICES_SUCCESS: createAction("GET_SERVICES_SUCCESS", (data) => {
    return data;
  }),
  GET_SERVICES_ERROR: createAction("GET_SERVICES_ERROR", (error) => {
    return {
      error,
    };
  }),
  RESET: createAction("RESET", () => {
    return null;
  }),
};

export const getVehiclesAction = (
  typeId,
  brand,
  model,
  engine,
  ecuBrand,
  ecuVersion
) => (dispatch) => {
  dispatch(vehiclesActions.GET_VEHICLES());
  const params = {
    type_id: typeId,
    brand_name: brand,
    model_name: model,
    engine_name: engine,
    ecu_brand: ecuBrand,
    ecu_version: ecuVersion,
  };
  api
    .get("/get_vehicles/" + authHeader() + "/1/200", { params })
    .then((res) => {
      dispatch(vehiclesActions.GET_VEHICLES_SUCCESS(res.data.data));
    })
    .catch((error) => dispatch(vehiclesActions.GET_VEHICLES_ERROR(error)));
};

export const getServicesAction = (vehicleId) => (dispatch) => {
  dispatch(vehiclesActions.GET_SERVICES());
  let lang = localStorage.getItem("i18nextLng").split("-")[0];
  if (!lang) {
    lang = "ES";
  }
  api
    .get("/get_services/" + authHeader() + "/" + vehicleId + "/" + lang)
    .then((res) => {
      if (res.data.data) {
        res.data.data.forEach((service) => {
          let ecuGain = +service.ecu_gain;
          let finalEcuGain = "";
          if (ecuGain !== 0) {
            let value = 0;
            switch (service.original_service_id) {
              case "5":
                finalEcuGain = ecuGain;
                break;
              case "6":
                value = ecuGain * 0.2;
                finalEcuGain = ecuGain + value;
                break;
              case "7":
                value = ecuGain * 0.1;
                finalEcuGain = ecuGain - value;
                break;
              default:
                finalEcuGain = "";
                break;
            }
            if (finalEcuGain !== "") {
              finalEcuGain = " +" + finalEcuGain + "hp";
            }
          }
          service.final_ecu_gain = finalEcuGain;
        });
      }
      dispatch(vehiclesActions.GET_SERVICES_SUCCESS(res.data.data));
    })
    .catch((error) => dispatch(vehiclesActions.GET_SERVICES_ERROR(error)));
};

export const reset = () => (dispatch) => {
  dispatch(vehiclesActions.RESET());
};
