import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import ScrollToTop from "../../utils/scrollToTop";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import unauthorizedError from "../../utils/unauthorizedError";
import {
  CircularProgress,
  Grid,
  TextField,
  Button,
  Backdrop,
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import GoogleMapReact from "google-map-react";
import { getAllBranchesAction } from "../../actions/branchesActions";
import { getUserAction } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { setTurnAction, reset } from "../../actions/turnsActions";
import "date-fns";
import { DesktopDatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { useTranslation } from "react-i18next";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

// Components
import Navbar from "../../components/Navbar/Navbar";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./SetTurn.css";

// Assets
import logo from "../../assets/images/logo.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function SetTurn() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mapDefaultProps, setMapDefaultProps] = useState(false);
  const [availableTimes] = useState([
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
  ]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const fetchingTurns = useSelector((state) => state.turnsReducer.fetching);
  const resTurn = useSelector((state) => state.turnsReducer.data);
  const fetchingBranches = useSelector(
    (state) => state.branchesReducer.fetching
  );
  const branches = useSelector((state) => state.branchesReducer.data);
  const [actualBranch, setActualBranch] = useState(false);
  const fetchingUser = useSelector((state) => state.userReducer.fetching);
  const user = useSelector((state) => state.userReducer.data);
  const userError = useSelector((state) => state.userReducer.userError);
  const turnsError = useSelector((state) => state.turnsReducer.turnsError);
  const { vehicle_id } = useParams();
  const [vehicleDomain, setVehicleDomain] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [message, setMessage] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const { t } = useTranslation();

  const handleClickOpenAlertSuccess = () => {
    setOpenAlertSuccess(true);
  };

  const handleCloseAlertSuccess = () => {
    setOpenAlertSuccess(false);
    history.push("/account");
  };

  const handleClickAlertMessage = (newMessage) => {
    setMessage(newMessage);
    setOpenAlertMessage(true);
  };

  const verifyRequired = () => {
    if (
      !(actualBranch.branch_id && user.user_id && vehicle_id && vehicleDomain)
    ) {
      handleClickAlertMessage(t("set-turn.alert-messages.0"));
      return false;
    } else {
      return true;
    }
  };

  const verifyPast = (before) => {
    const now = new Date();
    if (before && before < now) {
      handleClickAlertMessage(t("set-turn.alert-messages.1"));
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedTime.split(":")[0],
      selectedTime.split(":")[1],
      0
    );
    if (verifyPast(date) && verifyRequired()) {
      dispatch(
        setTurnAction(
          date,
          actualBranch.branch_id,
          user.user_id,
          vehicle_id,
          vehicleDomain
        )
      );
    }
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertMessage(false);
  };

  useEffect(() => {
    if (fetchingTurns) {
      handleToggleBackdrop();
    } else {
      handleCloseBackdrop();
      if (resTurn) {
        if (resTurn.ok) {
          handleClickOpenAlertSuccess();
        } else {
          if (resTurn.message) {
            handleClickAlertMessage(resTurn.message);
          }
        }
        dispatch(reset());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingTurns]);

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "rgb(16, 17, 17)";
    dispatch(getAllBranchesAction());
    dispatch(getUserAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && branches && !actualBranch) {
      if (!user.is_client) {
        const newActualBranch = branches.find(
          (branch) => branch.branch_id === user.branch_id
        );
        setActualBranch(newActualBranch);
        if (newActualBranch) {
          setMapDefaultProps({
            center: {
              lat: +newActualBranch.branch_lat,
              lng: +newActualBranch.branch_long,
            },
            zoom: 4,
          });
        }
      } else {
        setMapDefaultProps({
          center: {
            lat: -19.6337583,
            lng: -34.9017302,
          },
          zoom: 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, branches]);

  useEffect(() => {
    unauthorizedError(userError);
  }, [userError]);

  useEffect(() => {
    unauthorizedError(turnsError);
  }, [turnsError]);

  return (
    <React.Fragment>
      <Navbar />
      <ScrollToTop />
      <div className="topbar-overlay" />
      {!fetchingBranches && !fetchingUser && user && branches ? (
        <section className="set-turn">
          <div className="set-turn-content">
            <MdqLogo />
            <div className="set-turn-content-form">
              <div className="set-turn-content-form-branch-data">
                <h2 className="set-turn-content-form-data-title">
                  {actualBranch?.branch_name
                    ? actualBranch.branch_name
                    : t("set-turn.select-branch")}
                  {window.innerWidth <= 990 &&
                    window.innerHeight <= 720 &&
                    !actualBranch && (
                      <Link smooth to="#map">
                        <Button
                          variant="contained"
                          className="set-turn-down-button"
                          size="small"
                        >
                          <ArrowDownwardIcon />
                        </Button>
                      </Link>
                    )}
                </h2>
                {actualBranch && (
                  <React.Fragment>
                    <p className="set-turn-content-form-data-text">
                      {actualBranch.branch_email}
                    </p>
                    <p className="set-turn-content-form-data-text">
                      {actualBranch.branch_phone}
                    </p>
                    <p className="set-turn-content-form-data-text">
                      {actualBranch.province_name +
                        ", " +
                        actualBranch.country_name}
                    </p>
                  </React.Fragment>
                )}
              </div>
              <div className="set-turn-content-form-branch-content">
                <div className="set-turn-content-form-branch-content-turn"></div>
                <div className="set-turn-content-form-branch-content-inputs">
                  <ValidatorForm
                    className="set-turn-form"
                    onSubmit={handleSubmit}
                    instantValidate={false}
                  >
                    <Grid
                      container
                      justify="space-around"
                      className="date-picker-dialog"
                    >
                      <LocalizationProvider
                        dateAdapter={DateFnsUtils}
                        locale={
                          localStorage
                            .getItem("i18nextLng")
                            .split("-")[0]
                            .toLowerCase() === "es"
                            ? esLocale
                            : enLocale
                        }
                      >
                        <DesktopDatePicker
                          label={t("set-turn.inputs.0")}
                          disablePast
                          renderInput={(props) => <TextField {...props} />}
                          value={selectedDate}
                          onChange={(newValue) => setSelectedDate(newValue)}
                          cancelText="Cancelar"
                        />
                      </LocalizationProvider>
                      <FormControl className="set-turn-formcontrol">
                        <React.Fragment>
                          <InputLabel className="set-turn-formcontrol-label">
                            {t("set-turn.inputs.1")}
                          </InputLabel>
                          <Select
                            className="set-turn-formcontrol-select"
                            value={selectedTime}
                            onChange={(e) => setSelectedTime(e.target.value)}
                          >
                            {availableTimes.map((e, index) => (
                              <MenuItem
                                className="set-turn-formcontrol-item"
                                value={e}
                                key={index}
                              >
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      </FormControl>
                    </Grid>
                    <TextValidator
                      className="set-turn-text-input"
                      label={t("set-turn.inputs.2")}
                      name="vehicleDomain"
                      value={vehicleDomain}
                      onChange={(e) => setVehicleDomain(e.target.value)}
                    />
                    {actualBranch &&
                    selectedDate &&
                    selectedTime &&
                    vehicleDomain ? (
                      <button className="default-red-button set-turn-button animate__animated animate__fadeInLeft">
                        {t("set-turn.submit-button")}
                      </button>
                    ) : (
                      <div className="set-turn-content-form-data-alert animate__animated animate__flash">
                        <p className="set-turn-content-form-data-alert-text">
                          {t("set-turn.complete-button")}
                        </p>
                      </div>
                    )}
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
          <div className="set-turn-map" id="map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCeu8MnQ1ZAhHhOuA74ye301e5ErTNdjBI",
              }}
              defaultCenter={mapDefaultProps.center}
              defaultZoom={mapDefaultProps.zoom}
              options={{
                fullscreenControl: false,
                gestureHandling: "cooperative",
              }}
            >
              {branches
                .filter(
                  (branch) =>
                    (!user.is_client && branch.branch_id === user.branch_id) ||
                    user.is_client
                )
                .map((branch, index) => (
                  <button
                    key={index}
                    lat={branch.branch_lat}
                    lng={branch.branch_long}
                    className="mdq-set-turn-logo-container"
                    style={
                      user.is_client
                        ? branch.branch_id === actualBranch.branch_id
                          ? { backgroundColor: "grey" }
                          : { backgroundColor: "black" }
                        : branch.branch_id === actualBranch.branch_id
                        ? { backgroundColor: "grey", cursor: "grab" }
                        : { backgroundColor: "black" }
                    }
                    onClick={
                      user.is_client &&
                      (() => {
                        setActualBranch(branch);
                      })
                    }
                  >
                    <img src={logo} className="mdq-set-turn-logo" alt="" />
                  </button>
                ))}
            </GoogleMapReact>
          </div>
        </section>
      ) : (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}
      <AlertMessage
        open={openAlertMessage}
        handleClose={handleCloseAlertMessage}
        alertMessage={message}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openAlertSuccess}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAlertSuccess}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t("set-turn.turn-success-title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("set-turn.turn-success")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertSuccess} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SetTurn;
