import React, { useEffect, useState } from "react";
import ScrollToTop from "../../utils/scrollToTop";
import GoogleMapReact from "google-map-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBranchesAction,
  getTotalUsersAction,
} from "../../actions/branchesActions";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { Phone, LocationOn, Email } from "@material-ui/icons";
import Counter from "react-number-counter";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./Branches.css";

// Assets
import logo from "../../assets/images/logo.svg";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className="mdq-dialog-title" variant="h6">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Branches(props) {
  const dispatch = useDispatch();
  const fetchingBranches = useSelector(
    (state) => state.branchesReducer.fetching
  );
  const fetchingUsers = useSelector(
    (state) => state.branchesReducer.fetchingUsers
  );
  const branches = useSelector((state) => state.branchesReducer.data);
  const totalUsers = useSelector((state) => state.branchesReducer.users);
  const [open, setOpen] = useState(false);
  const [actualBranch, setActualBranch] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const defaultProps = {
    center: {
      lat: -19.6337583,
      lng: -34.9017302,
    },
    zoom: 1,
  };

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "transparent";
    dispatch(getAllBranchesAction());
    dispatch(getTotalUsersAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <ScrollToTop />
      <div className="topbar-overlay" />
      <section className="branches">
        <div className="branches-content">
          <div className="branches-content-header">
            <MdqLogo />
          </div>
          <div className="branches-content-data">
            <h2 className="branches-content-data-title">
              {t("branches.title")}
            </h2>
            <p className="branches-content-data-text">{t("branches.data")}</p>
            <div className="branches-content-data-counter-wrapper">
              {!fetchingUsers && totalUsers ? (
                <React.Fragment>
                  <p className="branches-content-data-counter-number">
                    <Counter
                      start={0}
                      end={Math.round(totalUsers)}
                      delay={10}
                    />
                  </p>
                  <p className="branches-content-data-counter-text">
                    {t("branches.branches-counter")}
                  </p>
                </React.Fragment>
              ) : (
                <div className="loading-container">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="branches-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCeu8MnQ1ZAhHhOuA74ye301e5ErTNdjBI",
              //key: "AIzaSyBod9w8JtmHrxGx0ICbnRfI8b8lKJKvxu0",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{
              fullscreenControl: false,
              gestureHandling: "cooperative",
            }}
          >
            {!fetchingBranches &&
              branches &&
              branches.map((branch, index) => (
                <button
                  key={index}
                  lat={branch.branch_lat}
                  lng={branch.branch_long}
                  className="mdq-branch-logo-container"
                  onClick={() => {
                    handleClickOpen();
                    setActualBranch(branch);
                  }}
                >
                  <img src={logo} className="mdq-branch-logo" alt="" />
                </button>
              ))}
          </GoogleMapReact>
        </div>
      </section>
      <Dialog
        onClose={handleClose}
        aria-labelledby="mdq-dialog-title"
        open={open}
        PaperProps={
          window.innerWidth >= 315 && {
            style: {
              minWidth: "300px",
            },
          }
        }
      >
        <DialogTitle id="mdq-dialog-title" onClose={handleClose}>
          {actualBranch.branch_name}
        </DialogTitle>
        <DialogContent dividers>
          <Typography className="mdq-dialog-data" gutterBottom>
            <div className="mdq-dialog-data-title-container">
              <LocationOn className="mdq-dialog-icon" />
              <p className="mdq-dialog-data-title"> {t("branches.address")}</p>
            </div>
            {actualBranch.branch_address +
              ", " +
              actualBranch.province_name +
              ", " +
              actualBranch.country_name}
          </Typography>
          <Typography className="mdq-dialog-data" gutterBottom>
            <div className="mdq-dialog-data-title-container">
              <Phone className="mdq-dialog-icon" />
              <p className="mdq-dialog-data-title"> {t("branches.phone")}</p>
            </div>
            {actualBranch.branch_phone}
          </Typography>
          <Typography className="mdq-dialog-data" gutterBottom>
            <div className="mdq-dialog-data-title-container">
              <Email className="mdq-dialog-icon" />
              <p className="mdq-dialog-data-title"> {t("branches.email")}</p>
            </div>
            {actualBranch.branch_email}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="mdq-dialog-button">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Branches;
