import React, { useState, useEffect, useLayoutEffect } from "react";
import ScrollToTop from "../../utils/scrollToTop";
import unauthorizedError from "../../utils/unauthorizedError";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../actions/userActions";
import { contactAction, reset } from "../../actions/contactActions";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import MdqLogo from "../../components/MdqLogo/MdqLogo";
import AlertMessage from "../../components/AlertMessage/AlertMessage";

// Styles
import "./ContactForm.css";

// Assets
import background from "./assets/background.jpg";
import bgLines from "./assets/bg-lines.svg";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ContactForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchingContact = useSelector(
    (state) => state.contactReducer.fetchingContact
  );
  const contactRes = useSelector((state) => state.contactReducer.contactRes);
  const contactError = useSelector(
    (state) => state.contactReducer.contactError
  );
  const userError = useSelector((state) => state.userReducer.userError);
  const [contactMessage, setContactMessage] = useState("");
  const [message, setMessage] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(contactAction(contactMessage));
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleClickAlertMessage = (newMessage) => {
    setMessage(newMessage);
    setOpenAlertMessage(true);
  };

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertMessage(false);
  };

  useLayoutEffect(() => {
    dispatch(getUserAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
  }, []);

  useEffect(() => {
    if (fetchingContact) {
      handleToggleBackdrop();
    } else {
      handleCloseBackdrop();
      if (contactRes && contactRes.message) {
        handleClickAlertMessage(contactRes.message);
        setContactMessage("");
        dispatch(reset());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingContact, contactRes]);

  useEffect(() => {
    unauthorizedError(contactError);
  }, [contactError]);

  useEffect(() => {
    unauthorizedError(userError);
  }, [userError]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <section className="contact-form">
        <MdqLogo />
        <img src={bgLines} className="contact-form-bg-lines" alt="" />
        <div className="contact-form-wrapper">
          <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
            <header className="contact-form-wrapper-header">
              <div className="contact-form-wrapper-title">
                <h2 className="mdq-dialog-title">{t("contact-form.title")}</h2>
              </div>
              <div className="contact-form-wrapper-button">
                <button className="default-red-button" type="submit">
                  {t("contact-form.button")}
                </button>
              </div>
            </header>
            <div className="contact-form-wrapper-content">
              <div className="contact-form-inputs">
                <TextValidator
                  className="contact-form-item"
                  placeholder={t("contact-form.placeholder")}
                  multiline
                  rows={11}
                  rowsMax={12}
                  name="contact"
                  value={contactMessage}
                  onChange={(e) => setContactMessage(e.target.value)}
                  validators={["required", "minStringLength: 20"]}
                  errorMessages={[
                    t("input-error-messages.required"),
                    t("input-error-messages.min-string-length-20"),
                  ]}
                />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </section>
      <AlertMessage
        open={openAlertMessage}
        handleClose={handleCloseAlertMessage}
        alertMessage={message}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
