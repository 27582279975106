import { createAction } from "redux-actions";
import { api } from "../utils/api";
import { authHeader } from "../utils/authHeader";

export const lastServicesActions = {
  GET_USER: createAction("GET_USER"),
  GET_USER_SUCCESS: createAction("GET_USER_SUCCESS", (data) => {
    return data;
  }),
  GET_USER_ERROR: createAction("GET_USER_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getUserAction = () => (dispatch) => {
  dispatch(lastServicesActions.GET_USER());
  api
    .get("/get_user/" + authHeader())
    .then((res) => {
      dispatch(lastServicesActions.GET_USER_SUCCESS(res.data.data));
    })
    .catch((error) => dispatch(lastServicesActions.GET_USER_ERROR(error)));
};
