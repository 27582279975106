import React from "react";
import { NavLink } from "react-router-dom";
import { slide as SlideMenu } from "react-burger-menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LanguageIcon from "@material-ui/icons/Language";
import { withTranslation } from "react-i18next";

// Style
import "./Navbar.css";

// Assets
import hamburgerIcon from "./assets/hamburger-icon.svg";
import crossIcon from "./assets/cross-icon.svg";
import background from "./assets/menu.png";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      languageSelectorOpen: null,
    };
  }

  handleClickLanguageSelector(event) {
    this.setState({ languageSelectorOpen: event.currentTarget });
  }

  handleCloseLanguageSelector() {
    this.setState({ languageSelectorOpen: null });
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 20) {
      document.querySelector(".navbar-menu").className =
        "navbar-menu navbar-scroll";
    } else {
      document.querySelector(".navbar-menu").className = "navbar-menu";
    }
  };

  render() {
    const { t, i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    const ROUTES = [
      {
        id: 1,
        name: t("navbar.routes.home"),
        url: "/home",
      },
      {
        id: 2,
        name: t("navbar.routes.chiptuning"),
        url: "/chiptuning",
      },
      {
        id: 3,
        name: t("navbar.routes.rolling-roads"),
        url: "/rolling-roads",
      },
      {
        id: 4,
        name: t("navbar.routes.vehicles"),
        url: "/vehicle-search",
      },
      {
        id: 5,
        name: t("navbar.routes.alientech"),
        url: "/home/6",
      },
      {
        id: 6,
        name: t("navbar.routes.events"),
        url: "/events",
      },
      {
        id: 7,
        name: t("navbar.routes.we"),
        url: "/we",
      },
      {
        id: 8,
        name: t("navbar.routes.my-account"),
        url: "/account",
      },
      {
        id: 9,
        name: t("navbar.routes.branches"),
        url: "/branches",
      },
      {
        id: 10,
        name: t("shop"),
        link: "http://shop.mdqracing.com/",
      },
    ];

    return (
      <nav className="navbar-menu">
        <SlideMenu
          right
          className="menu"
          customBurgerIcon={<img src={hamburgerIcon} alt="" />}
          customCrossIcon={
            <img src={crossIcon} className="custom-cross-icon" alt="" />
          }
          scaleDown
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          onMouseDown={() => this.closeMenu()}
        >
          <button
            className="language-selector-menu"
            onClick={(e) => {
              this.handleClickLanguageSelector(e);
            }}
          >
            <LanguageIcon className="language-selector-menu-icon" />
            <span className="language-selector-menu-text">
              {localStorage.getItem("i18nextLng").split("-")[0]}
            </span>
          </button>
          <Menu
            id="language-selector-menu"
            anchorEl={this.state.languageSelectorOpen}
            keepMounted
            open={Boolean(this.state.languageSelectorOpen)}
            onClose={() => {
              this.handleCloseLanguageSelector();
            }}
          >
            <MenuItem
              onClick={() => {
                this.handleCloseLanguageSelector();
                changeLanguage("es");
              }}
            >
              <span className="language-selector-menu-item">
                {t("navbar.lang.es")}
              </span>
            </MenuItem>
            <MenuItem
              className="language-selector-menu-item"
              onClick={() => {
                this.handleCloseLanguageSelector();
                changeLanguage("en");
              }}
            >
              <span className="language-selector-menu-item">
                {t("navbar.lang.en")}
              </span>
            </MenuItem>
          </Menu>
          <img src={background} className="menu-background" alt="" />
          {ROUTES.map((route, index) =>
            route.url ? (
              <NavLink
                activeStyle={
                  route.name !== "Alientech"
                    ? { color: "#ffffff" }
                    : { color: "#7b7b7b" }
                }
                className="menu-item"
                to={route.url}
                onClick={() => this.closeMenu()}
                key={index}
              >
                {route.name}
                <sup className="menu-item-sup">0{route.id}</sup>
              </NavLink>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="menu-item"
                href={route.link}
                onClick={() => this.closeMenu()}
                key={index}
              >
                {route.name}
                {route.id > 9 ? <sup className="menu-item-sup">{route.id}</sup> : <sup className="menu-item-sup">0{route.id}</sup>}
              </a>
            )
          )}
        </SlideMenu>
      </nav>
    );
  }
}

export default withTranslation()(Navbar);
