import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { getServicesAction } from "../../../../actions/vehiclesActions";
import { CircularProgress } from "@material-ui/core";
import KeyboardArrowRight from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTranslation } from "react-i18next";

// Components
import VehicleCard from "../VehicleCard/VehicleCard";

// Styles
import "./VehicleCardDetail.css";

// Assets
import icon1 from "./assets/icons/icon1.svg";
import icon2 from "./assets/icons/icon2.svg";

export default function VehicleCardDetail(props) {
  const [ecuServiceAmount, setEcuServiceAmount] = useState(false);
  const [serviceAmount, setServiceAmount] = useState(false);
  const [additionalSection, setAdditionalSection] = useState(false);
  const [selectedService, setSelectedService] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchingServices = useSelector(
    (state) => state.vehiclesReducer.fetchingServices
  );
  const services = useSelector((state) => state.vehiclesReducer.services);
  const { t } = useTranslation();

  const handleClickOpenAdditionalSection = (service) => {
    setAdditionalSection(true);
    setSelectedService(service);
    setEcuServiceAmount(service.ecu_service_amount);
    setServiceAmount(service.service_amount);
  };

  const handleClickCloseAdditionalSection = () => {
    setAdditionalSection(false);
    setSelectedService(false);
  };

  const handleClickOpen = () => {
    document.documentElement.style.overflowY = "hidden";
    setOpen(true);
  };

  const handleClose = () => {
    document.documentElement.style.overflowY = "auto";
    setOpen(false);
  };

  const handleCheckboxChange = (event, amount) => {
    if (event.target.checked) {
      setEcuServiceAmount(+ecuServiceAmount + +amount);
      setServiceAmount(+serviceAmount + +amount);
    } else {
      setEcuServiceAmount(+ecuServiceAmount - +amount);
      setServiceAmount(+serviceAmount - +amount);
    }
  };

  useEffect(() => {
    if (open === true) {
      setAdditionalSection(false);
      dispatch(getServicesAction(props.vehicle.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, t]);

  return (
    <div>
      <Button className="vehicle-card-open-button" onClick={handleClickOpen}>
        <VehicleCard vehicle={props.vehicle} />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Grid container className="vehicle-card-detail-grid">
          <div className="vehicle-card-detail">
            <button
              onClick={handleClose}
              aria-label="close"
              className="vehicle-card-detail-close-button"
            >
              <CloseIcon className="vehicle-card-detail-close-icon" />
            </button>
            <div className="vehicle-card-detail-header">
              <div className="vehicle-card-detail-header-content">
                <div className="vehicle-card-detail-header-title-container">
                  <h3 className="vehicle-card-detail-header-title">
                    {props.vehicle.band_name + " " + props.vehicle.model_name}
                  </h3>
                </div>
                <div className="vehicle-card-detail-header-data-container">
                  <img
                    className="vehicle-card-detail-header-data-icon"
                    src={icon1}
                    alt=""
                  />
                  <p className="vehicle-card-detail-header-data" title="Motor">
                    {props.vehicle.engine_name}
                  </p>
                </div>
                {props.vehicle.ecu_brand && (
                  <div className="vehicle-card-detail-header-data-container">
                    <img
                      className="vehicle-card-detail-header-data-icon"
                      src={icon2}
                      alt=""
                    />
                    <p className="vehicle-card-detail-header-data" title="ECU">
                      {props.vehicle.ecu_brand +
                        " " +
                        props.vehicle.ecu_version}
                    </p>
                  </div>
                )}
              </div>
              <div className="vehicle-card-detail-header-button">
                <NavLink
                  onClick={handleClose}
                  className="default-red-button"
                  to={"/vehicle-search/set-turn/" + props.vehicle.id}
                >
                  {t("vehicle-card-detail.button")}
                </NavLink>
              </div>
            </div>
            <div
              className={
                additionalSection
                  ? "vehicle-card-detail-content"
                  : "vehicle-card-detail-content vehicle-card-detail-shadow"
              }
            >
              {!additionalSection ? (
                <React.Fragment>
                  <div className="vehicle-card-detail-services-wrapper">
                    {!fetchingServices ? (
                      services ? (
                        <React.Fragment>
                          <h4 className="vehicle-card-detail-services-title">
                            {t("vehicle-card-detail.services")}
                          </h4>
                          {services
                            .filter((e) => e.service_optional === "0")
                            .map((service) => (
                              <div
                                className={
                                  "vehicle-card-detail-service additional-service-button"
                                }
                                onClick={() =>
                                  handleClickOpenAdditionalSection(service)
                                }
                                key={service.service_id}
                              >
                                <div className="vehicle-card-detail-service-decoration"></div>
                                <div className="vehicle-card-detail-service-text-wrapper">
                                  <p className="vehicle-card-detail-service-text">
                                    {service.service_name +
                                      service.final_ecu_gain}
                                  </p>
                                  <KeyboardArrowRight className="vehicle-card-detail-service-text-icon" />
                                </div>
                              </div>
                            ))}
                        </React.Fragment>
                      ) : (
                        <h4 className="vehicle-card-detail-services-title">
                          {t("vehicle-card-detail.no-services")}
                        </h4>
                      )
                    ) : (
                      <div className="loading-container">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <div className="vehicle-card-detail-image-container">
                    <img
                      className="vehicle-card-detail-image"
                      src={props.vehicle.vehicle_photo}
                      alt=""
                    />
                  </div>
                </React.Fragment>
              ) : (
                services && (
                  <React.Fragment>
                    <Button
                      onClick={handleClickCloseAdditionalSection}
                      color="primary"
                      className="vehicle-card-detail-additional-services-back-button"
                    >
                      <ArrowBackIosIcon className="vehicle-card-detail-additional-services-back-icon" />
                    </Button>
                    {selectedService && (
                      <div className="vehicle-card-detail-additional-services">
                        <h4 className="vehicle-card-detail-additional-services-title">
                          {services.filter((e) => e.service_optional === "1")
                            .length > 1
                            ? t("vehicle-card-detail.additional-services")
                            : t("vehicle-card-detail.no-additional-services")}
                        </h4>
                        {services
                          .filter((e) => e.service_optional === "1")
                          .map((service) => (
                            <div className="vehicle-card-detail-additional-services-item" key={service.service_id}>
                              {service.service_amount && (
                                <Checkbox
                                  color="primary"
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event,
                                      service.service_amount
                                    )
                                  }
                                />
                              )}
                              <p className="vehicle-card-detail-additional-services-text">
                                {service.service_name +
                                  (service.service_amount ? " - " : "")}
                                <span style={{ color: "black" }}>
                                  {selectedService.coin_symbol}
                                </span>
                                {service.service_amount && (
                                  <span style={{ color: "rgb(73, 72, 72)" }}>
                                    {service.service_amount}
                                  </span>
                                )}
                              </p>
                            </div>
                          ))}
                        {selectedService.service_amount && (
                          <div className="vehicle-card-detail-additional-services-prices">
                            <Divider className="default-divider" />
                            <div className="vehicle-card-detail-additional-services-price-container">
                              <p className="vehicle-card-detail-additional-services-text">
                                {t("vehicle-card-detail.total")}
                              </p>
                              <p className="vehicle-card-detail-additional-services-price-text">
                                <span style={{ color: "black" }}>
                                  {selectedService.coin_symbol}
                                </span>
                                <span id="ecu-service-amount">
                                  {ecuServiceAmount}
                                </span>
                              </p>
                            </div>
                            <div className="vehicle-card-detail-additional-services-price-container">
                              <p className="vehicle-card-detail-additional-services-text">
                                {t("vehicle-card-detail.suggested")}
                              </p>
                              <p className="vehicle-card-detail-additional-services-price-text">
                                <span style={{ color: "black" }}>
                                  {selectedService.coin_symbol}
                                </span>
                                <span id="service-amount">{serviceAmount}</span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </Grid>
      </Dialog>
    </div>
  );
}
