import React from "react";

// Components
import TeamCard from "../TeamCard/TeamCard";

// Styles
import "./TeamSectionCards.css";

function TeamSectionCards(props) {
  return (
    <div className="team-section-card">
      <h2 className="team-section-card-name">{props.name}</h2>
      <div className="team-section-card-content">
        {props.team.map((e, index) => (
          <TeamCard member={e} color={props.color} key={index} />
        ))}
      </div>
    </div>
  );
}

export default TeamSectionCards;
