import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history }) {
  useEffect(() => {
    document.getElementsByTagName("html")[0].scrollTop = 0;
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
