import React, { useEffect, useState, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import ScrollToTop from "../../utils/scrollToTop";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import Gallery from "../../components/Gallery/Gallery";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./RollingRoads.css";

// Assets
import image0 from "./assets/images/background.jpg";
import arrowIcon from "./assets/icons/arrow-icon.svg";
import image1 from "./assets/images/1.png";
import image2 from "./assets/images/2.jpg";
import image3 from "./assets/images/3.jpg";
import image4 from "./assets/images/4.jpg";
import icon1 from "./assets/icons/1.svg";
import icon2 from "./assets/icons/2.svg";
import icon3 from "./assets/icons/3.svg";
import icon4 from "./assets/icons/4.svg";

function RollingRoads(props) {
  const [actualSection, setActualSection] = useState(0);
  const sectionsRef = useRef(null);
  const rollingRoadsContent = useRef(null);
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      name: t("rolling-roads.items.1.name"),
    },
    {
      id: 2,
      name: t("rolling-roads.items.2.name"),
      info: [
        t("rolling-roads.items.2.info.1"),
        t("rolling-roads.items.2.info.2"),
        t("rolling-roads.items.2.info.3"),
        t("rolling-roads.items.2.info.4"),
        t("rolling-roads.items.2.info.5"),
      ],
      gallery: [image1, image2, image3, image4],
    },
    {
      id: 3,
      image: image3,
      name: t("rolling-roads.items.3.name"),
      textList: [
        {
          icon: icon1,
          text: t("rolling-roads.items.3.icon-info.1"),
        },
        {
          icon: icon2,
          text: t("rolling-roads.items.3.icon-info.2"),
        },
        {
          icon: icon3,
          text: t("rolling-roads.items.3.icon-info.3"),
        },
        {
          icon: icon4,
          text: t("rolling-roads.items.3.icon-info.4"),
        },
      ],
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "transparent";
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      {items.map((e, index) => (
        <img src={e.image} style={{ display: "none" }} key={index} alt="" />
      ))}
      <div className="topbar-overlay" />
      <Navbar />
      <section
        className="rolling-roads"
        style={
          items[actualSection].id !== 2
            ? {
                backgroundImage: `url(${image0})`,
              }
            : {
                backgroundImage: "none",
              }
        }
      >
        <div
          className="rolling-roads-sections"
          ref={sectionsRef}
          style={
            items[actualSection].id === 2
              ? {
                  backgroundImage: `url(${image0})`,
                }
              : {
                  backgroundImage: "none",
                }
          }
        >
          <div className="rolling-roads-sections-header">
            <MdqLogo />
          </div>
          <div className="rolling-roads-sections-content">
            <div className="rolling-roads-sections-info-wrapper">
              <h2 className="rolling-roads-sections-title">
                {items[actualSection].name}
              </h2>
              <h3 className="rolling-roads-sections-description">
                {t("rolling-roads.main-text")}
              </h3>
            </div>
            <div className="rolling-roads-sections-indicators-wrapper">
              {items.map((e, index) => (
                <Link
                  key={index}
                  className="rolling-roads-sections-indicator"
                  onClick={() => {
                    rollingRoadsContent &&
                      (rollingRoadsContent.current.scrollTop = 0);
                    setActualSection(index);
                  }}
                  smooth
                  to={e.id !== 1 ? "#data" : "#"}
                  style={
                    items[actualSection].name === e.name
                      ? {
                          borderColor: "rgb(233, 30, 30)",
                        }
                      : { borderColor: "#bab4b4" }
                  }
                >
                  <img
                    src={arrowIcon}
                    className="rolling-roads-sections-indicator-icon"
                    style={
                      items[actualSection].name === e.name
                        ? {
                            visibility: "visible",
                          }
                        : { visibility: "hidden" }
                    }
                    alt=""
                  />
                  0{e.id}
                  <span style={{ visibility: "hidden" }}>.</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div
          className="rolling-roads-content-wrapper"
          style={
            items[actualSection].id === 2
              ? { backgroundColor: "rgb(16, 17, 17)" }
              : { backgroundColor: "transparent" }
          }
          id="data"
        >
          <div className="rolling-roads-content" ref={rollingRoadsContent}>
            {items[actualSection].info && (
              <React.Fragment>
                <div className="rolling-roads-content-header" />
                <div className="rolling-roads-content-info-wrapper">
                  {items[actualSection].info.map((e, index) => (
                    <p className="rolling-roads-content-info-text" key={index}>
                      {e}
                    </p>
                  ))}
                  <Gallery gallery={items[actualSection].gallery} />
                </div>
              </React.Fragment>
            )}
            {items[actualSection].textList && (
              <React.Fragment>
                <div className="rolling-roads-content-text-list-wrapper">
                  {items[actualSection].textList.map((e, index) => (
                    <div
                      className="rolling-roads-content-text-list-text-wrapper"
                      key={index}
                    >
                      <div className="rolling-roads-content-text-list-icon-wrapper">
                        <img
                          src={e.icon}
                          className="rolling-roads-content-text-list-icon"
                          alt=""
                        />
                      </div>
                      <p className="rolling-roads-content-text-list-text">
                        {e.text}
                      </p>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default RollingRoads;
