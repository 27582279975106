import { authReducer } from "./authReducer";
import { lastServicesReducer } from "./lastServicesReducer";
import { branchesReducer } from "./branchesReducer";
import { userReducer } from "./userReducer";
import { vehiclesTypesReducer } from "./vehiclesTypesReducer";
import { vehiclesReducer } from "./vehiclesReducer";
import { turnsReducer } from "./turnsReducer";
import { contactReducer } from "./contactReducer";

const { combineReducers } = require("redux");

const rootReducer = combineReducers({
  authReducer: authReducer,
  lastServicesReducer: lastServicesReducer,
  branchesReducer: branchesReducer,
  userReducer: userReducer,
  vehiclesTypesReducer: vehiclesTypesReducer,
  vehiclesReducer: vehiclesReducer,
  turnsReducer: turnsReducer,
  contactReducer: contactReducer,
});

export default rootReducer;
