import { handleActions } from "redux-actions";

export const vehiclesReducer = handleActions(
  {
    GET_VEHICLES: (state, action) => {
      return {
        ...state,
        vehiclesError: null,
        fetching: true,
      };
    },
    GET_VEHICLES_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    GET_VEHICLES_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        vehiclesError: action,
      };
    },
    GET_SERVICES: (state, action) => {
      return {
        ...state,
        servicesError: null,
        fetchingServices: true,
      };
    },
    GET_SERVICES_SUCCESS: (state, action) => {
      return {
        ...state,
        fetchingServices: false,
        services: action.payload,
      };
    },
    GET_SERVICES_ERROR: (state, action) => {
      return {
        ...state,
        fetchingServices: false,
        servicesError: action,
      };
    },
    RESET: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
  },
  {
    fetching: false,
    vehiclesError: null,
    data: [],
    fetchingServices: null,
    servicesError: null,
    services: null,
  }
);
