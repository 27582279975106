import { createAction } from "redux-actions";
import { api } from "../utils/api";

export const lastServicesActions = {
  GET_ALL_LAST_SERVICES: createAction("GET_ALL_LAST_SERVICES"),
  GET_ALL_LAST_SERVICES_SUCCESS: createAction(
    "GET_ALL_LAST_SERVICES_SUCCESS",
    (data) => {
      return data;
    }
  ),
  GET_ALL_LAST_SERVICES_ERROR: createAction(
    "GET_ALL_LAST_SERVICES_ERROR",
    (error) => {
      return {
        error,
      };
    }
  ),
};

export const getAllLastServicesAction = () => (dispatch) => {
  dispatch(lastServicesActions.GET_ALL_LAST_SERVICES());
  let lang = localStorage.getItem("i18nextLng").split("-")[0];
  if (!lang) {
    lang = "ES";
  }
  api
    .get("/get_last_services/token/" + lang)
    .then((res) => {
      dispatch(
        lastServicesActions.GET_ALL_LAST_SERVICES_SUCCESS(res.data.data)
      );
    })
    .catch((error) =>
      dispatch(lastServicesActions.GET_ALL_LAST_SERVICES_ERROR(error))
    );
};
