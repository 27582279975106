import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../../../actions/authActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Styles
import "./UpdatePassword.css";

export default function UpdatePassword() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValues({ ...values, password: "" });
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePassword(values.password));
    handleClose();
  };

  return (
    <div>
      <button onClick={handleClickOpen} className="default-small-button">
        <span>{t("update-password.button")}</span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="update-password-dialog"
      >
        <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
          <DialogContent>
            <DialogContentText className="mdq-dialog-title">
              {t("update-password.title")}
            </DialogContentText>
            <div className="update-password-form-inputs">
              <TextValidator
                className="update-password-form-item"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                label={t("update-password.password.label")}
                name="password"
                onChange={(e) =>
                  setValues({ ...values, password: e.target.value })
                }
                validators={["required", "minStringLength: 4"]}
                errorMessages={[
                  t("input-error-messages.required"),
                  t("input-error-messages.min-string-length"),
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="mdq-dialog-button">
              {t("update-password.cancel-button")}
            </Button>
            <Button type="submit" className="mdq-dialog-button">
              {t("update-password.accept-button")}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
