import { handleActions } from "redux-actions";

export const authReducer = handleActions(
  {
    LOGIN: (state, action) => {
      return {
        ...state,
        authError: null,
        fetching: true,
      };
    },
    LOGIN_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    LOGIN_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action,
      };
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        authError: null,
        fetching: true,
      };
    },
    LOGOUT_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    LOGOUT_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action,
      };
    },
    RESET_PASSWORD: (state, action) => {
      return {
        ...state,
        authError: null,
        fetching: true,
      };
    },
    RESET_PASSWORD_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    RESET_PASSWORD_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action,
      };
    },
    UPDATE_PASSWORD: (state, action) => {
      return {
        ...state,
        authError: null,
        fetching: true,
      };
    },
    UPDATE_PASSWORD_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    UPDATE_PASSWORD_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action,
      };
    },
    SIGNIN: (state, action) => {
      return {
        ...state,
        authError: null,
        fetching: true,
      };
    },
    SIGNIN_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    SIGNIN_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action,
      };
    },
    RESET: (state, action) => {
      return {
        fetching: false,
        data: action.payload,
        authError: null,
      };
    },
  },
  { fetching: false, authError: null, data: null }
);
