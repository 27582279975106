import React, { useState, useEffect, useRef } from "react";
import ScrollToTop from "../../utils/scrollToTop";
import unauthorizedError from "../../utils/unauthorizedError";
import { useDispatch, useSelector } from "react-redux";
import { resellerAction, reset } from "../../actions/contactActions";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import MdqLogo from "../../components/MdqLogo/MdqLogo";
import AlertMessage from "../../components/AlertMessage/AlertMessage";

// Styles
import "./ResellerForm.css";

// Assets
import background from "./assets/background.jpg";
import bgLines from "./assets/bg-lines.svg";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ResellerForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchingReseller = useSelector(
    (state) => state.contactReducer.fetchingReseller
  );
  const resellerRes = useSelector((state) => state.contactReducer.resellerRes);
  const resellerError = useSelector(
    (state) => state.contactReducer.resellerError
  );
  const [resellerData, setResellerData] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    country: "",
    province: "",
    city: "",
    gRecaptcha: "",
  });
  const recaptchaRef = useRef(null);
  const [message, setMessage] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resellerAction(resellerData));
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleClickAlertMessage = (newMessage) => {
    setMessage(newMessage);
    setOpenAlertMessage(true);
  };

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertMessage(false);
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
  }, []);

  useEffect(() => {
    if (fetchingReseller) {
      handleToggleBackdrop();
    } else {
      handleCloseBackdrop();
      if (resellerRes && resellerRes.message) {
        handleClickAlertMessage(resellerRes.message);
        if (resellerRes.ok) {
          setResellerData({
            name: "",
            lastname: "",
            email: "",
            phone: "",
            country: "",
            province: "",
            city: "",
            gRecaptcha: "",
          });
          if (recaptchaRef?.current) {
            recaptchaRef.current.reset();
          }
        }
        dispatch(reset());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingReseller, resellerRes]);

  useEffect(() => {
    unauthorizedError(resellerError);
  }, [resellerError]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <section className="reseller-form">
        <MdqLogo />
        <img src={bgLines} className="reseller-form-bg-lines" alt="" />
        <div className="reseller-form-wrapper">
          <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
            <header className="reseller-form-wrapper-header">
              <h2 className="reseller-form-wrapper-title">
                {t("reseller-form.title")}
              </h2>
              <div className="reseller-form-wrapper-button">
                <button className="default-red-button" type="submit">
                  {t("reseller-form.button")}
                </button>
              </div>
            </header>
            <div className="reseller-form-wrapper-content">
              <div className="reseller-form-info">
                <p className="reseller-form-info-text">
                  {t("reseller-form.text")}
                </p>
                <small className="reseller-form-info-text-small">
                  {t("reseller-form.privacy")}
                </small>
                <div className="reseller-form-captcha-wrapper">
                  <ReCAPTCHA
                    className="reseller-form-captcha"
                    theme="dark"
                    ref={recaptchaRef}
                    size={props.windowWidth > 850 ? "normal" : "compact"}
                    sitekey="6LfPkO8ZAAAAAAfvL5PPKi67vUu7Syr5wtluh08i"
                    onChange={(value) =>
                      setResellerData({ ...resellerData, gRecaptcha: value })
                    }
                  />
                </div>
              </div>
              <div className="reseller-form-inputs">
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.0")}
                  name="name"
                  value={resellerData.name}
                  onChange={(e) =>
                    setResellerData({ ...resellerData, name: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={[t("input-error-messages.required")]}
                />
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.1")}
                  name="lastname"
                  value={resellerData.lastname}
                  onChange={(e) =>
                    setResellerData({
                      ...resellerData,
                      lastname: e.target.value,
                    })
                  }
                  validators={["required"]}
                  errorMessages={[t("input-error-messages.required")]}
                />
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.2")}
                  name="email"
                  value={resellerData.email}
                  onChange={(e) =>
                    setResellerData({ ...resellerData, email: e.target.value })
                  }
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    t("input-error-messages.required"),
                    t("input-error-messages.is-email"),
                  ]}
                />
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.3")}
                  name="phone"
                  value={resellerData.phone}
                  onChange={(e) =>
                    setResellerData({ ...resellerData, phone: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={[t("input-error-messages.required")]}
                />
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.4")}
                  name="country"
                  value={resellerData.country}
                  onChange={(e) =>
                    setResellerData({
                      ...resellerData,
                      country: e.target.value,
                    })
                  }
                  validators={["required"]}
                  errorMessages={[t("input-error-messages.required")]}
                />
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.5")}
                  name="province"
                  value={resellerData.province}
                  onChange={(e) =>
                    setResellerData({
                      ...resellerData,
                      province: e.target.value,
                    })
                  }
                  validators={["required"]}
                  errorMessages={[t("input-error-messages.required")]}
                />
                <TextValidator
                  className="reseller-form-item"
                  label={t("reseller-form.inputs.6")}
                  name="city"
                  value={resellerData.city}
                  onChange={(e) =>
                    setResellerData({ ...resellerData, city: e.target.value })
                  }
                  validators={["required"]}
                  errorMessages={[t("input-error-messages.required")]}
                />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </section>
      <AlertMessage
        open={openAlertMessage}
        handleClose={handleCloseAlertMessage}
        alertMessage={message}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
