import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../../../components/Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import MdqLogo from "../../../../components/MdqLogo/MdqLogo";
import AlientechLogo from "../../../../components/AlientechLogo/AlientechLogo";

// Styles
import "../../Home.css";
import "./Home02.css";

// Assets
import arrow from "../../../../assets/icons/arrow.svg";
import slide1 from "./assets/slide1.png";
import slide2 from "./assets/slide2.png";
import slide3 from "./assets/slide3.png";
import slide4 from "./assets/slide4.png";
import slide5 from "./assets/slide5.png";
import slide6 from "./assets/slide6.png";
import slide7 from "./assets/slide7.png";
import slide8 from "./assets/slide8.png";

function Home02(props) {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1460 },
      items: 4,
    },
    tablet1: {
      breakpoint: { max: 1460, min: 1078 },
      items: 3,
    },
    tablet2: {
      breakpoint: { max: 1078, min: 720 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  const ITEMS1 = [
    {
      image: slide1,
      title: t("home02.items1.title"),
      link: "/reseller",
      message: t("home02.items1.message"),
      desc: t("home02.items1.desc"),
    },
    {
      image: slide2,
      title: t("home02.items2.title"),
      link: "/contact",
      message: t("home02.items2.message"),
      desc: t("home02.items2.desc"),
    },
    {
      image: slide3,
      title: t("home02.items3.title"),
      link: "/contact",
      message: t("home02.items3.message"),
      desc: t("home02.items3.desc"),
    },
    {
      image: slide4,
      title: t("home02.items4.title"),
      link: "/contact",
      message: t("home02.items4.message"),
      desc: t("home02.items4.desc"),
    },    
  ];

  const ITEMS2 = [
    {
      image: slide5,
      title: t("home03.items4.title"),
      desc: t("home03.items4.desc"),
    },
    {
      image: slide6,
      title: t("home03.items5.title"),
      desc: t("home03.items5.desc"),
    },
    {
      image: slide7,
      title: t("home03.items6.title"),
      desc: t("home03.items6.desc"),
    },
    {
      image: slide8,
      title: t("home03.items7.title"),
      desc: t("home03.items7.desc"),
    },
  ];

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.style.backgroundImage = `url(${props.backgroundImage})`;
  }, [props.backgroundImage]);

  const mapItems = (items) => {
    return items.map((item, index) => (
      <div className="carousel-02-item" key={item.title}>
        <div className="carousel-02-item-img-container">
          {item.link ? (
            <NavLink className="carousel-02-item-img-link" to={item.link}>
              <Tooltip
                title={<p className="tooltip-text">{item.message}</p>}
                arrow
                placement="top"
              >
                <img className="carousel-02-item-img" src={item.image} alt="" />
              </Tooltip>
            </NavLink>
          ) : (
            <img className="carousel-02-item-img" src={item.image} alt="" />
          )}
        </div>
        <div className="carousel-02-item-text-container">
          <br />
          <h2 className="carousel-02-item-text-container-title">
            {item.title}
          </h2>
          <p className="carousel-02-item-text-container-desc">{item.desc}</p>
        </div>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <div className="topbar-overlay" />
      <Navbar />
      <Sidebar section={props.section} />
      <section className="home-section">
        <MdqLogo />
        <div className="home-slider-container">
          <div className="home-slider-container-row1 home-02-row1">
            <Carousel
              key={props.section}
              responsive={responsive}
              ssr={true}
              keyBoardControl={true}
              swipeable={true}
              draggable={true}
              containerClass="carousel-02-container"
              itemClass="carousel-item-padding-40-px"
            >
              {props.items === "items1" ? mapItems(ITEMS1) : mapItems(ITEMS2)}
            </Carousel>
          </div>
          <div className="home-slider-container-row2">
            <div className="home-slider-container-row2-cl-start">
              <AlientechLogo />
            </div>
            <div className="home-slider-container-row2-cl-center">
              <NavLink
                className="arrow-down-button"
                to={"/home/" + (+props.section + 1)}
              >
                <img src={arrow} alt="" className="arrow-down" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Home02;
