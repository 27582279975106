import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../../../../utils/scrollToTop";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllLastServicesAction } from "../../../../actions/lastServicesActions";
import { useTranslation } from "react-i18next";
import { LocationOn } from "@material-ui/icons";

// Components
import Navbar from "../../../../components/Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import MdqLogo from "../../../../components/MdqLogo/MdqLogo";
import AlientechLogo from "../../../../components/AlientechLogo/AlientechLogo";

// Styles
import "../../Home.css";
import "./Home05.css";

// Assets
import image1 from "./assets/1.jpg";
import icon1 from "./assets/icon1.svg";
import icon2 from "./assets/icon2.svg";
import arrow from "../../../../assets/icons/arrow.svg";

function Home05(props) {
  const carouselRef = useRef(null);
  const dispatch = useDispatch();
  const fetchingLastServices = useSelector(
    (state) => state.lastServicesReducer.fetching
  );
  const lastServices = useSelector((state) => state.lastServicesReducer.data);
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1460 },
      items: 1,
    },
    tablet1: {
      breakpoint: { max: 1460, min: 1078 },
      items: 1,
    },
    tablet2: {
      breakpoint: { max: 1078, min: 720 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${image1})`;
    dispatch(getAllLastServicesAction());
  }, [dispatch, t]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <Sidebar section={"5"} />
      <section className="home-section lastjobs-section">
        <MdqLogo />
        <div className="home-slider-container">
          <div className="home-slider-container-row1">
            <p className="home-slider-title lastjobs-title">
              {t("home05.title")}
            </p>
            <div className="home-slider-content">
              <div className="carousel-05-container">
                {!fetchingLastServices && lastServices ? (
                  <Carousel
                    ref={carouselRef}
                    responsive={responsive}
                    keyBoardControl={true}
                    swipeable={true}
                    draggable={true}
                    containerClass="carousel-05"
                  >
                    {lastServices &&
                      lastServices.map((service, index) => (
                        <div className="carousel-05-item" key={index}>
                          <div className="carousel-05-item-img-container">
                            <img
                              className="carousel-05-item-img"
                              src={service.vehicle_photo}
                              alt=""
                            />
                          </div>
                          <div className="carousel-05-specs-container">
                            <div className="carousel-05-specs">
                              {lastServices && (
                                <React.Fragment>
                                  <div className="carousel-05-specs-wrapper">
                                    <h2 className="carousel-05-specs-title">
                                      {service.vehicle_brand +
                                        " " +
                                        service.vehicle_model}
                                    </h2>
                                  </div>
                                  <div className="carousel-05-specs-wrapper">
                                    <img
                                      src={icon2}
                                      className="carousel-05-specs-icon"
                                      alt=""
                                    />
                                    <p className="carousel-05-specs-text">
                                      {service.vehicle_engine}
                                    </p>
                                  </div>
                                  <div className="carousel-05-specs-wrapper">
                                    <img
                                      src={icon1}
                                      className="carousel-05-specs-icon"
                                      alt=""
                                    />
                                    <p className="carousel-05-specs-text">
                                      {service.service_name}
                                    </p>
                                  </div>
                                  <div className="carousel-05-specs-wrapper">
                                    <LocationOn className="carousel-05-specs-icon" />
                                    <p className="carousel-05-specs-text">
                                      {service.branch_name}
                                    </p>
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                ) : (
                  <div className="loading-container">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
            <p className="home-slider-text lastjobs-text">
              {t("home05.lastjobs-text")}
            </p>
          </div>
          <div className="home-slider-container-row2">
            <div className="home-slider-container-row2-cl-start">
              <AlientechLogo />
            </div>
            <div className="home-slider-container-row2-cl-center">
              <NavLink className="arrow-down-button" to="/home/6">
                <img src={arrow} alt="" className="arrow-down" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Home05;
