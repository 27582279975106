import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { signinUserAction } from "../../../../actions/authActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

// Styles
import "./Signin.css";

export default function Signin() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: "",
    name: "",
    lastname: "",
    phone: "",
  });
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValues({
      email: "",
      name: "",
      lastname: "",
      phone: "",
    });
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      signinUserAction(values.email, values.name, values.lastname, values.phone)
    );
    handleClose();
  };

  return (
    <div>
      <button className="default-button" onClick={handleClickOpen}>
        <span>{t("signin.button")}</span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="signin-dialog"
      >
        <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
          <DialogContent>
            <DialogContentText className="mdq-dialog-title">
              {t("signin.title")}
            </DialogContentText>
            <div className="signin-form-inputs">
              <TextValidator
                className="signin-form-item"
                label={t("signin.email.label")}
                name="email"
                value={values.email}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                validators={["required", "isEmail"]}
                errorMessages={[
                  t("input-error-messages.required"),
                  t("input-error-messages.is-email"),
                ]}
              />
            </div>
            <div className="signin-form-inputs">
              <TextValidator
                className="signin-form-item"
                label={t("signin.name.label")}
                name="name"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                validators={["required"]}
                errorMessages={[t("input-error-messages.required")]}
              />
            </div>
            <div className="signin-form-inputs">
              <TextValidator
                className="signin-form-item"
                label={t("signin.lastname.label")}
                name="lastname"
                value={values.lastname}
                onChange={(e) =>
                  setValues({ ...values, lastname: e.target.value })
                }
                validators={["required"]}
                errorMessages={[t("input-error-messages.required")]}
              />
            </div>
            <div className="signin-form-inputs">
              <TextValidator
                className="signin-form-item"
                label={t("signin.phone.label")}
                name="phone"
                value={values.phone}
                onChange={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
                validators={["required"]}
                errorMessages={[t("input-error-messages.required")]}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="mdq-dialog-button">
              {t("signin.cancel-button")}
            </Button>
            <Button type="submit" className="mdq-dialog-button">
              {t("signin.accept-button")}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
