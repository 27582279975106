import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

// Styles
import "./Gallery.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Gallery(props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1460 },
      items: 1,
    },
    tablet1: {
      breakpoint: { max: 1460, min: 1078 },
      items: 1,
    },
    tablet2: {
      breakpoint: { max: 1078, min: 720 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  const handleClickOpen = () => {
    document.documentElement.style.overflow = "hidden";
    setOpen(true);
  };

  const handleClose = () => {
    document.documentElement.style.overflow = "auto";
    setOpen(false);
  };

  const images = props.gallery.map((image, index) => (
    <img src={image} className="dot-img" key={index} alt="" />
  ));

  const CustomDot = ({ index, onClick, active }) => {
    return (
      <button
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classNames("dot-img-container", {
          "dot-img-container-active": active,
        })}
      >
        {React.Children.toArray(images)[index]}
      </button>
    );
  };

  return (
    <div>
      <button
        className="default-button gallery-button"
        onClick={handleClickOpen}
      >
        <span>{t("gallery.button")}</span>
      </button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Grid container className="carousel-gallery-grid">
          <button
            onClick={handleClose}
            aria-label="close"
            className="gallery-close-button"
          >
            <CloseIcon className="gallery-close-icon" />
          </button>
          <Carousel
            showDots
            responsive={responsive}
            ssr={true}
            keyBoardControl={true}
            swipeable={true}
            draggable={true}
            customDot={<CustomDot />}
            renderButtonGroupOutside={true}
            containerClass="carousel-gallery-container"
            itemClass="carousel-item-padding-40-px"
          >
            {props.gallery &&
              props.gallery.map((image, index) => (
                <div className="carousel-gallery-item" key={index}>
                  <div className="carousel-gallery-item-img-container">
                    <img
                      className="carousel-gallery-item-img"
                      src={image}
                      alt=""
                    />
                  </div>
                </div>
              ))}
          </Carousel>
        </Grid>
      </Dialog>
    </div>
  );
}
