import React from "react";
import { NavLink } from "react-router-dom";

// Styles
import "./MdqLogo.css";

// Assets
import logo from "./assets/icons/mdq-logo.svg";
import logoWhite from "./assets/icons/mdq-logo-white.svg";

export default function MdqLogo(props) {
  return (
    <NavLink to="/home/01" style={{ position: "absolute" }}>
      <img
        src={logoWhite}
        onMouseOver={(e) => (e.currentTarget.src = logo)}
        onMouseOut={(e) => (e.currentTarget.src = logoWhite)}
        alt="logo"
        className="mdq-logo"
      />
    </NavLink>
  );
}
