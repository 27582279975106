import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";

// Components
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

// Pages
import Home01 from "./pages/Home/components/Home01/Home01";
import Home02 from "./pages/Home/components/Home02/Home02";
import Home04 from "./pages/Home/components/Home04/Home04";
import Home05 from "./pages/Home/components/Home05/Home05";
import Home06 from "./pages/Home/components/Home06/Home06";
import Home07 from "./pages/Home/components/Home07/Home07";
import Chiptuning from "./pages/Chiptuning/Chiptuning";
import RollingRoads from "./pages/RollingRoads/RollingRoads";
import VehicleSearch from "./pages/VehicleSearch/VehicleSearch";
import SetTurn from "./pages/SetTurn/SetTurn";
import Events from "./pages/Events/Events";
import We from "./pages/We/We";
import Account from "./pages/Account/Account";
import Branches from "./pages/Branches/Branches";
import ContactForm from "./pages/ContactForm/ContactForm";
import ResellerForm from "./pages/ResellerForm/ResellerForm";
import Login from "./pages/Login/Login";

// Home
import backgroundImage1 from "./pages/Home/components/Home02/assets/1.jpg";
import backgroundImage2 from "./pages/Home/components/Home02/assets/2.jpg";

// Preload
import image1 from "./pages/Home/components/Home01/assets/1.jpg";
import image2 from "./pages/Home/components/Home01/assets/2.jpg";
import image3 from "./pages/Home/components/Home01/assets/3.jpg";
import image4 from "./pages/Home/components/Home01/assets/4.jpg";
import image5 from "./pages/Home/components/Home04/assets/1.jpg";
import image6 from "./pages/Home/components/Home05/assets/1.jpg";
import image7 from "./pages/Home/components/Home06/assets/1.jpg";
import mobile from "./pages/Home/components/Home04/assets/mobile.png";
import logoMdq from "./components/MdqLogo/assets/icons/mdq-logo.svg";
import logoWhiteMdq from "./components/MdqLogo/assets/icons/mdq-logo-white.svg";
import logoAlientech from "./components/AlientechLogo/assets/icons/alientech-logo.svg";
import logoWhiteAlientech from "./components/AlientechLogo/assets/icons/alientech-logo-white.svg";
import divider from "./pages/Home/components/Home07/assets/divider.svg";
import accountImage from "./pages/Account/assets/images/background.jpg";
import weImage from "./pages/We/assets/images/1.jpg";
import eventsImage from "./pages/Events/assets/images/background.jpg";
import rollingRoadsImage from "./pages/RollingRoads/assets/images/background.jpg";
import chiptuningImage from "./pages/Chiptuning/assets/images/1.jpg";
import contactImage from "./pages/ContactForm/assets/background.jpg";
import resellerImage from "./pages/ResellerForm/assets/background.jpg";

function App() {
  let history = useHistory();
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const { t } = useTranslation();

  const handleWheel = (e) => {
    const url = history.location.pathname.split("/");
    if (url[1] === "home") {
      e.preventDefault();
      let link;
      if (e.deltaY > 0) {
        link = +url[2] + 1;
      } else {
        link = +url[2] - 1;
      }
      if (link < 8 && link > 0) {
        history.push("/home/" + link);
      }
    }
  };

  const updateWindowSizes = () => {
    if (window.innerWidth !== windowWidth) {
      setWindowWidth(window.innerWidth);
    }
    if (window.innerHeight !== windowHeight) {
      setWindowHeight(window.innerHeight);
    }
  };

  const handleViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const updateWindowDimensions = () => {
    updateWindowSizes();
    handleViewportHeight();
  };

  const handleScroll = () => {
    const overlay = document.getElementsByClassName("topbar-overlay")[0];
    const mdqLogo = document.getElementsByClassName("mdq-logo")[0];
    const bmBurger = document.getElementsByClassName("bm-burger-button")[0];
    if (overlay) {
      if (document.documentElement.scrollTop > 10) {
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.741)";
        if (mdqLogo) {
          mdqLogo.style.top = "10px";
        }
        bmBurger.style.top = "5px";
      } else {
        overlay.style.backgroundColor = "transparent";
        if (mdqLogo) {
          mdqLogo.style.top = "55px";
        }
        bmBurger.style.top = "50px";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener("orientationchange", updateWindowDimensions);
    document.onscroll = () => {
      handleScroll();
    };
    setTimeout(() => updateWindowDimensions(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleWheelEvent = debounce((e) => {
      handleWheel(e);
    }, 100);
    window.addEventListener("wheel", handleWheelEvent, { passive: false });
    return () =>
      window.removeEventListener("wheel", handleWheelEvent, { passive: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
      </MetaTags>
      <img src={image1} style={{ display: "none" }} alt="" />
      <img src={image2} style={{ display: "none" }} alt="" />
      <img src={image3} style={{ display: "none" }} alt="" />
      <img src={image4} style={{ display: "none" }} alt="" />
      <img src={image5} style={{ display: "none" }} alt="" />
      <img src={image6} style={{ display: "none" }} alt="" />
      <img src={image7} style={{ display: "none" }} alt="" />
      <img src={backgroundImage1} style={{ display: "none" }} alt="" />
      <img src={backgroundImage2} style={{ display: "none" }} alt="" />
      <img src={mobile} style={{ display: "none" }} alt="" />
      <img src={logoMdq} style={{ display: "none" }} alt="" />
      <img src={logoWhiteMdq} style={{ display: "none" }} alt="" />
      <img src={logoAlientech} style={{ display: "none" }} alt="" />
      <img src={logoWhiteAlientech} style={{ display: "none" }} alt="" />
      <img src={divider} style={{ display: "none" }} alt="" />
      <img src={accountImage} style={{ display: "none" }} alt="" />
      <img src={weImage} style={{ display: "none" }} alt="" />
      <img src={eventsImage} style={{ display: "none" }} alt="" />
      <img src={rollingRoadsImage} style={{ display: "none" }} alt="" />
      <img src={chiptuningImage} style={{ display: "none" }} alt="" />
      <img src={contactImage} style={{ display: "none" }} alt="" />
      <img src={resellerImage} style={{ display: "none" }} alt="" />
      <Switch>
        <Route exact path="/home/1" render={(props) => <Home01 {...props} />} />
        <Route
          exact
          path="/home/2"
          render={() => (
            <Home02
              items={"items1"}
              backgroundImage={backgroundImage1}
              section="2"
            />
          )}
        />
        <Route
          exact
          path="/home/3"
          render={(props) => (
            <Home02
              {...props}
              items={"items2"}
              backgroundImage={backgroundImage2}
              section="3"
            />
          )}
        />
        <Route exact path="/home/4" render={() => <Home04 />} />
        <Route exact path="/home/5" render={() => <Home05 />} />
        <Route exact path="/home/6" render={() => <Home06 />} />
        <Route exact path="/home/7" render={() => <Home07 />} />
        <Route
          exact
          path="/chiptuning"
          render={(props) => (
            <Chiptuning
              {...props}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
            />
          )}
        />
        <Route
          exact
          path="/rolling-roads"
          render={(props) => (
            <RollingRoads
              {...props}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
            />
          )}
        />
        <PrivateRoute exact path="/vehicle-search" component={VehicleSearch} />
        <PrivateRoute
          exact
          path="/vehicle-search/set-turn/:vehicle_id"
          component={SetTurn}
        />
        <Route
          exact
          path="/events"
          render={(props) => (
            <Events
              {...props}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
            />
          )}
        />
        <Route
          exact
          path="/we"
          render={(props) => <We {...props} windowWidth={windowWidth} />}
        />
        <PrivateRoute exact path="/account" component={Account} />
        <Route exact path="/branches" component={Branches} />
        <PrivateRoute exact path="/contact" component={ContactForm} />
        <Route
          exact
          path="/reseller"
          render={(props) => (
            <ResellerForm {...props} windowWidth={windowWidth} />
          )}
        />
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Redirect from="/" to="/home/1" />
      </Switch>
    </React.Fragment>
  );
}

export default App;
