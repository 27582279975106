import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../../../../utils/scrollToTop";
import Tooltip from "@material-ui/core/Tooltip";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../../../components/Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import MdqLogo from "../../../../components/MdqLogo/MdqLogo";
import AlientechLogo from "../../../../components/AlientechLogo/AlientechLogo";

// Styles
import "../../Home.css";
import "./Home06.css";

// Assets
import image1 from "./assets/1.jpg";
import arrowSite from "./assets/arrow.svg";
import alientechLogo from "./assets/alientech-logo.svg";
import arrow from "../../../../assets/icons/arrow.svg";

// Products
import items from "./products";

function Home06(props) {
  const [products, setProducts] = useState(0);
  const carouselRef = useRef(null);
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1460 },
      items: 1,
    },
    tablet1: {
      breakpoint: { max: 1460, min: 1078 },
      items: 1,
    },
    tablet2: {
      breakpoint: { max: 1078, min: 720 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  useLayoutEffect(() => {
    setProducts(items);
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${image1})`;
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <Sidebar section={"6"} />
      <section className="home-section">
        <MdqLogo />
        <div className="home-slider-container alientech-slider-container">
          <div className="home-slider-container-row1 flex-center">
            <div className="home-06-alientech">
              <img
                src={alientechLogo}
                alt="alientech logo"
                className="alientech-06-logo"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.alientech-tools.com/es"
                className="home-06-button"
              >
                {t("home06.visit-button")}
                <img src={arrowSite} className="home-06-button-arrow" alt="" />
              </a>
            </div>
            <Carousel
              ref={carouselRef}
              responsive={responsive}
              ssr={true}
              keyBoardControl={true}
              swipeable={true}
              draggable={true}
              containerClass="carousel-06-container alientech-carousel"
              itemClass="carousel-item-padding-40-px"
            >
              {products &&
                products.map((item, index) => (
                  <div className="carousel-06-item" key={index}>
                    <div className="carousel-06-item-img-container">
                      <NavLink
                        className="carousel-06-item-img-link"
                        to="/contact"
                      >
                        <Tooltip
                          title={
                            <p className="tooltip-text">
                              {t("home06.tooltip")}
                            </p>
                          }
                          arrow
                          placement="top"
                        >
                          <img
                            className="carousel-06-item-img"
                            src={item.image}
                            alt=""
                          />
                        </Tooltip>
                      </NavLink>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
          <div className="home-slider-container-row2">
            <div className="home-slider-container-row2-cl-start">
              <AlientechLogo />
            </div>
            <div className="home-slider-container-row2-cl-center">
              <NavLink className="arrow-down-button" to="/home/7">
                <img src={arrow} alt="" className="arrow-down" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Home06;
