import { handleActions } from "redux-actions";

export const userReducer = handleActions(
  {
    GET_USER: (state, action) => {
      return {
        ...state,
        userError: null,
        fetching: true,
      };
    },
    GET_USER_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    GET_USER_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        userError: action,
      };
    },
  },
  { fetching: false, userError: null, data: null }
);
