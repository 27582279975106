import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translation_en from "../locales/en/translation.json";
import translation_es from "../locales/es/translation.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: translation_es,
      },
      en: {
        translation: translation_en,
      },
    },
    load: "languageOnly",
    fallbackLng: "es",
    whitelist: ["es", "en"],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
