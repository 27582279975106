import React, { useEffect, useState } from "react";
import ScrollToTop from "../../utils/scrollToTop";
import unauthorizedError from "../../utils/unauthorizedError";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { getTypesAction } from "../../actions/vehiclesTypesActions";
import { getVehiclesAction, reset } from "../../actions/vehiclesActions";
import {
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import VehicleCardDetail from "./components/VehicleCardDetail/VehicleCardDetail";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./VehicleSearch.css";

// Assets
import bgLines from "./assets/icons/bg-lines.svg";

function VehicleSearch(props) {
  const dispatch = useDispatch();
  const fetchingTypes = useSelector(
    (state) => state.vehiclesTypesReducer.fetching
  );
  const APItypes = useSelector((state) => state.vehiclesTypesReducer.data);
  const fetchingVehicles = useSelector(
    (state) => state.vehiclesReducer.fetching
  );
  const vehicles = useSelector((state) => state.vehiclesReducer.data);
  const [vehiclesTypes, setVehiclesTypes] = useState(APItypes);
  const vehiclesError = useSelector(
    (state) => state.vehiclesReducer.vehiclesError
  );
  const servicesError = useSelector(
    (state) => state.vehiclesReducer.servicesError
  );
  const vehiclesTypesError = useSelector(
    (state) => state.vehiclesTypesReducer.vehiclesTypesError
  );
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [engine, setEngine] = useState("");
  const [ecuBrand, setEcuBrand] = useState("");
  const [ecuVersion, setEcuVersion] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(20);
  const [selectError, setSelectError] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    if (type === "") {
      setSelectError(true);
    } else {
      setPage(1);
      e.preventDefault();
      dispatch(
        getVehiclesAction(
          type.trim(),
          brand.trim(),
          model.trim(),
          engine.trim(),
          ecuBrand.trim(),
          ecuVersion.trim()
        )
      );
      setSelectError(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    document.getElementsByTagName("html")[0].scrollTop = 0;
    setPage(newPage);
  };

  useEffect(() => {
    unauthorizedError(vehiclesError);
  }, [vehiclesError]);

  useEffect(() => {
    unauthorizedError(servicesError);
  }, [servicesError]);

  useEffect(() => {
    unauthorizedError(vehiclesTypesError);
  }, [vehiclesTypesError]);

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "rgb(24, 25, 26)";
    document.documentElement.style.overflow = "auto";
  }, []);

  useEffect(() => {
    if (APItypes) {
      setVehiclesTypes(APItypes);
    }
  }, [fetchingTypes, APItypes]);

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTypesAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <section className="vehicle-search">
        <MdqLogo />
        <img src={bgLines} className="vehicle-search-bg-lines" alt="" />
        <div className="vehicle-search-content-container">
          <div className="vehicle-search-filters">
            <div className="vehicle-search-filters-header">
              <h2 className="vehicle-search-filters-header-title">
                {t("vehicles.title")}
              </h2>
            </div>
            <ValidatorForm
              className="vehicle-search-filters-content"
              onSubmit={handleSubmit}
              instantValidate={false}
            >
              <FormControl
                className="vehicle-search-filters-formcontrol"
                error={selectError ? true : false}
              >
                {!fetchingTypes && vehiclesTypes ? (
                  <React.Fragment>
                    <InputLabel className="vehicle-search-filters-formcontrol-label">
                      {t("vehicles.inputs.0")}
                    </InputLabel>
                    <Select
                      className="vehicle-search-filters-formcontrol-select"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {vehiclesTypes.map((e, index) => (
                        <MenuItem
                          className="vehicle-search-filters-formcontrol-item"
                          value={e.id}
                          key={index}
                        >
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {t("input-error-messages.required")}
                    </FormHelperText>
                  </React.Fragment>
                ) : (
                  <div className="loading-container">
                    <CircularProgress />
                  </div>
                )}
              </FormControl>
              <TextValidator
                className="vehicle-search-filters-input"
                label={t("vehicles.inputs.1")}
                name="brand"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
              <TextValidator
                className="vehicle-search-filters-input"
                label={t("vehicles.inputs.2")}
                name="model"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
              <TextValidator
                className="vehicle-search-filters-input"
                label={t("vehicles.inputs.3")}
                name="engine"
                value={engine}
                onChange={(e) => setEngine(e.target.value)}
              />
              <TextValidator
                className="vehicle-search-filters-input"
                label={t("vehicles.inputs.4")}
                name="ecuBrand"
                value={ecuBrand}
                onChange={(e) => setEcuBrand(e.target.value)}
              />
              <TextValidator
                className="vehicle-search-filters-input"
                label={t("vehicles.inputs.5")}
                name="ecuVersion"
                value={ecuVersion}
                onChange={(e) => setEcuVersion(e.target.value)}
              />
              <div className="vehicle-search-filters-search">
                <div className="default-button-container">
                  <button type="submit" className="default-red-button">
                    <span> {t("vehicles.button")}</span>
                  </button>
                </div>
              </div>
            </ValidatorForm>
          </div>
          {!fetchingVehicles ? (
            vehicles && vehicles.length >= 1 ? (
              <div className="vehicle-search-data">
                {vehicles
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((e, index) => (
                    <VehicleCardDetail vehicle={e} key={index} />
                  ))}
                <Grid
                  className="vehicle-search-pagination"
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>
                    {vehicles && (
                      <Pagination
                        size={window.innerWidth > 450 ? "medium" : "small"}
                        component="div"
                        page={page}
                        count={Math.ceil(vehicles.length / rowsPerPage)}
                        onChange={handleChangePage}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className="vehicle-search-data">
                <p className="vehicle-search-empty-list"></p>
              </div>
            )
          ) : (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}
        </div>
        <Footer />
      </section>
    </React.Fragment>
  );
}

export default VehicleSearch;
