import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../../actions/authActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

// Styles
import "./ResetPassword.css";

export default function ResetPassword() {
  const [email, setEmail] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEmail();
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email));
    handleClose();
  };

  return (
    <div>
      <Button className="reset-password-button" onClick={handleClickOpen}>
        <span>{t("reset-password.button")}</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="reset-password-dialog"
      >
        <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
          <DialogContent>
            <DialogContentText className="mdq-dialog-title">
              {t("reset-password.title")}
            </DialogContentText>
            <div className="reset-password-form-inputs">
              <TextValidator
                className="reset-password-form-item"
                label={t("reset-password.email.label")}
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                validators={["required", "isEmail"]}
                errorMessages={[
                  t("input-error-messages.required"),
                  t("input-error-messages.is-email"),
                ]}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="mdq-dialog-button">
              {t("reset-password.cancel-button")}
            </Button>
            <Button type="submit" className="mdq-dialog-button">
              {t("reset-password.accept-button")}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
