import React from "react";

// Styles
import "./AlientechLogo.css";

// Assets
import logo from "./assets/icons/alientech-logo.svg";
import logoWhite from "./assets/icons/alientech-logo-white.svg";

export default function AlientechLogo() {
  return (
    <img
      src={logoWhite}
      onMouseOver={(e) => (e.currentTarget.src = logo)}
      onMouseOut={(e) => (e.currentTarget.src = logoWhite)}
      alt="alientech logo"
      className="alientech-logo"
    />
  );
}
