import { handleActions } from "redux-actions";

export const branchesReducer = handleActions(
  {
    GET_ALL_BRANCHS: (state, action) => {
      return {
        ...state,
        branchesError: null,
        fetching: true,
      };
    },
    GET_ALL_BRANCHS_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    GET_ALL_BRANCHS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        branchesError: action,
      };
    },
    GET_TOTAL_USERS: (state, action) => {
      return {
        ...state,
        branchesError: null,
        fetchingUsers: true,
      };
    },
    GET_TOTAL_USERS_SUCCESS: (state, action) => {
      return {
        ...state,
        fetchingUsers: false,
        users: action.payload,
      };
    },
    GET_TOTAL_USERS_ERROR: (state, action) => {
      return {
        ...state,
        fetchingUsers: false,
        branchesError: action,
      };
    },
  },
  {
    fetching: false,
    branchesError: null,
    data: null,
    fetchingUsers: false,
    users: false,
  }
);
