import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ScrollToTop from "../../utils/scrollToTop";
import unauthorizedError from "../../utils/unauthorizedError";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../actions/userActions";
import { logoutUserAction, reset } from "../../actions/authActions";
import {
  getAllTurnsAction,
  takeTurnAction,
  reset as resetTurns,
  resetTakeTurn,
} from "../../actions/turnsActions";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import UpdatePassword from "./components/UpdatePassword/UpdatePassword";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./Account.css";

// Assets
import image from "./assets/images/background.jpg";
import qr from "./assets/images/qr.jpg";

function Account(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchingAuth = useSelector((state) => state.authReducer.fetching);
  const fetchingUser = useSelector((state) => state.userReducer.fetching);
  const fetchingTakeTurn = useSelector(
    (state) => state.turnsReducer.fetchingTakeTurn
  );
  const takeTurn = useSelector((state) => state.turnsReducer.takeTurn);
  const resAuth = useSelector((state) => state.authReducer.data);
  const user = useSelector((state) => state.userReducer.data);
  const turns = useSelector((state) => state.turnsReducer.turns);
  const authError = useSelector((state) => state.authReducer.authError);
  const userError = useSelector((state) => state.userReducer.userError);
  const turnsError = useSelector((state) => state.turnsReducer.turnsError);
  const [contact, setContact] = useState(false);
  const [message, setMessage] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { t } = useTranslation();

  const signOut = () => {
    dispatch(logoutUserAction(history));
  };

  const handleToggleContact = () => {
    setContact(!contact);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleClickAlertMessage = (newMessage) => {
    setMessage(newMessage);
    setOpenAlertMessage(true);
  };

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertMessage(false);
  };

  useLayoutEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "transparent";
    dispatch(resetTurns());
    dispatch(getUserAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchingAuth) {
      handleToggleBackdrop();
    } else {
      handleCloseBackdrop();
      if (resAuth) {
        if (resAuth.message) {
          handleClickAlertMessage(resAuth.message);
        }
        dispatch(reset());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingAuth]);

  useEffect(() => {
    if (user && user.user_id) {
      if (user.is_client) {
        dispatch(getAllTurnsAction(user.user_id));
      } else {
        dispatch(getAllTurnsAction(null, user.branch_id));
        dispatch(resetTakeTurn());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, takeTurn]);

  useEffect(() => {
    unauthorizedError(authError);
  }, [authError]);

  useEffect(() => {
    unauthorizedError(userError);
  }, [userError]);

  useEffect(() => {
    unauthorizedError(turnsError);
  }, [turnsError]);

  return (
    <React.Fragment>
      <img src={qr} style={{ display: "none" }} alt="" />
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <section className="account">
        <div
          className="account-sections"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="account-sections-buttons">
            <MdqLogo />
          </div>
          <div className="account-sections-content">
            <NavLink
              className="default-button account-button"
              to="/vehicle-search"
            >
              <span> {t("account.vehicle-search-button")}</span>
            </NavLink>
          </div>
        </div>
        {!contact ? (
          <div className="account-content-wrapper">
            {!fetchingUser && user ? (
              <React.Fragment>
                <div className="account-content">
                  <div className="account-content-user-info">
                    <h2 className="account-content-user-info-name">
                      {user?.user_name + " " + user?.user_lastname}
                    </h2>
                    <h3 className="account-content-user-info-title">
                      {t("account.my-turns-title")}
                    </h3>
                  </div>
                  <div className="account-content-table-container">
                    <table className="account-content-table">
                      <thead>
                        <tr className="account-table-row-header">
                          <th>{t("account.table-cl-header1")}</th>
                          <th>{t("account.table-cl-header2")}</th>
                          <th style={{ border: 0 }}>
                            {t("account.table-cl-header3")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {turns && turns.length > 0 && (
                          <React.Fragment>
                            {turns &&
                              turns.map((turn, index) => (
                                <tr
                                  className="account-table-row-content"
                                  key={index}
                                >
                                  <td>
                                    {turn.dateDay +
                                      "/" +
                                      turn.dateMonth +
                                      "/" +
                                      turn.dateYear}
                                    <br />
                                    {turn.timeHour + ":" + turn.timeMinutes}
                                  </td>
                                  <td>{turn.vehicle_domain}3</td>
                                  <td style={{ border: 0 }}>
                                    {turn.taken === "1" ? (
                                      t("account.turn-taken-true")
                                    ) : user.is_client ? (
                                      t("account.turn-taken-false")
                                    ) : (
                                      <Button
                                        variant="outlined"
                                        className="turn-accept-button"
                                        color="primary"
                                        onClick={() => {
                                          !fetchingTakeTurn &&
                                            dispatch(takeTurnAction(turn.id));
                                        }}
                                        style={
                                          !fetchingTakeTurn
                                            ? { cursor: "pointer" }
                                            : { cursor: "default" }
                                        }
                                      >
                                        {!fetchingTakeTurn ? (
                                          t("account.take-turn")
                                        ) : (
                                          <CircularProgress className="take-turn-circular-progress" />
                                        )}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="account-manage-buttons">
                  <button
                    onClick={handleToggleContact}
                    className="default-small-button"
                  >
                    <span>{t("account.direct-contact-button")}</span>
                  </button>
                  <UpdatePassword />
                  <button
                    onClick={signOut}
                    className="default-small-button"
                    to="branches"
                  >
                    <span>{t("account.logout-button")}</span>
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}
          </div>
        ) : (
          <div className="account-contact-wrapper">
            <div className="account-contact">
              <div className="account-contact-qr-wrapper">
                <img className="account-contact-qr" src={qr} alt="" />
              </div>
              <p className="account-contact-text">
                {t("account.direct-contact.text")}
              </p>
              <div className="account-manage-buttons">
                <NavLink className="default-small-button" to="/contact">
                  <span>{t("account.direct-contact.contact-form-button")}</span>
                </NavLink>
                <button
                  onClick={handleToggleContact}
                  className="default-small-button"
                >
                  <span>{t("account.direct-contact.back-button")}</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
      <AlertMessage
        open={openAlertMessage}
        handleClose={handleCloseAlertMessage}
        alertMessage={message}
      />
    </React.Fragment>
  );
}

export default Account;
