import React, { useEffect, useState, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import ScrollToTop from "../../utils/scrollToTop";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import Gallery from "../../components/Gallery/Gallery";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./Events.css";

// Assets
import image0 from "./assets/images/background.jpg";

function Events(props) {
  const [actualSection, setActualSection] = useState(0);
  const sectionsRef = useRef(null);
  const chiptuningContent = useRef(null);
  const { t } = useTranslation();

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const items = [
    {
      id: -1,
      name: t("events.title"),
      info: [t("events.subtitle")],
    },
    {
      id: 0,
      name: "2014 - " + t("events.items.0.name"),
      info: [t("events.items.0.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2014 - Dakar/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 1,
      name: "2015 - " + t("events.items.1.name"),
      gallery: importAll(
        require.context(
          "./assets/gallery/2015 - Ruta 40/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 2,
      name: "2015 - " + t("events.items.0.name"),
      info: [t("events.items.2.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2015 - Curso Sheraton/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 3,
      name: "2015 - " + t("events.items.3.name"),
      info: [t("events.items.3.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2015 - Sema Show/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 4,
      name: "2016 - " + t("events.items.4.name"),
      gallery: importAll(
        require.context(
          "./assets/gallery/2016 - Apertura Nuevo Local Alientech/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 5,
      name: "2016 - " + t("events.items.5.name"),
      info: [t("events.items.5.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2016 - Automechanika/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 6,
      name: "2016 - " + t("events.items.6.name"),
      info: [t("events.items.6.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2016 - Sema Show/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 7,
      name: "2017 - " + t("events.items.7.name"),
      gallery: importAll(
        require.context(
          "./assets/gallery/2017 - Curso Paraguay/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 8,
      name: "2018 - " + t("events.items.8.name"),
      info: [t("events.items.8.desc")],
    },
    {
      id: 9,
      name: "2018 - " + t("events.items.9.name"),
      gallery: importAll(
        require.context(
          "./assets/gallery/2018 - Curso Uruguay/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 10,
      name: "2018 - " + t("events.items.10.name"),
      info: [t("events.items.10.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2018 - Meeting Puerto Madero/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 11,
      name: "2018 - " + t("events.items.11.name"),
      info: [t("events.items.11.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2018 - Automechanika/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 12,
      name: "2019 - " + t("events.items.12.name"),
      gallery: importAll(
        require.context(
          "./assets/gallery/2019 - Inauguración Chechips MDQR/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 13,
      name: "2019 - " + t("events.items.13.name"),
      gallery: importAll(
        require.context(
          "./assets/gallery/2019 - Curso en Performance Center/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 14,
      name: "2019 - " + t("events.items.14.name"),
      info: [t("events.items.14.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2019 - Sema Show/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 15,
      name: "2022 - " + t("events.items.15.name"),
      info: [t("events.items.15.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2022 - Automechanika/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
    {
      id: 16,
      name: "2022 - " + t("events.items.16.name"),
      info: [t("events.items.16.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2022 - Motortec Madrid/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },    
    {
      id: 17,
      name: "2024 - " + t("events.items.17.name"),
      info: [t("events.items.17.desc")],
      gallery: importAll(
        require.context(
          "./assets/gallery/2024 - Automechanika/",
          false,
          /\.(png|JPG|jpeg|svg|jpg)$/
        )
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "transparent";
    if (sectionsRef.current) {
      sectionsRef.current.style.backgroundImage = `url(${image0})`;
    }
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      {items.map((e, index) => (
        <img src={e.image} style={{ display: "none" }} key={index} alt="" />
      ))}
      <div className="topbar-overlay" />
      <Navbar />
      <section className="events">
        <div className="events-sections" ref={sectionsRef}>
          <div className="events-sections-header">
            <MdqLogo />
          </div>
          <div className="events-sections-content">
            <div className="events-sections-buttons-wrapper">
              <Link
                onClick={() => {
                  chiptuningContent &&
                    (chiptuningContent.current.scrollTop = 0);
                  setActualSection(0);
                }}
                smooth
                to="#data"
                className="events-sections-button"
              >
                <h2
                  className="events-sections-title"
                  style={
                    actualSection === 0
                      ? {
                          color: "#ffffff",
                        }
                      : { color: "#bab4b4" }
                  }
                >
                  {t("events.title")}
                </h2>
              </Link>
              {items.map(
                (e, index) =>
                  e.id !== -1 && (
                    <Link
                      key={e.id}
                      className="events-sections-button"
                      onClick={() => {
                        chiptuningContent &&
                          (chiptuningContent.current.scrollTop = 0);
                        setActualSection(index);
                      }}
                      smooth
                      to="#data"
                    >
                      <h3
                        className="events-sections-name"
                        style={
                          items[actualSection]
                            ? items[actualSection].name === e.name
                              ? {
                                  color: "#ffffff",
                                }
                              : { color: "#bab4b4" }
                            : { color: "#bab4b4" }
                        }
                      >
                        {e.name}
                      </h3>
                    </Link>
                  )
              )}
            </div>
          </div>
        </div>
        <div className="events-content-wrapper" id="data">
          <div className="events-content" ref={chiptuningContent}>
            {items[actualSection] &&
              (actualSection === 0 ? (
                <div className="events-content-info-main">
                  {items[actualSection].info.map((item, index) => (
                    <p className="events-content-info-text-main" key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                <div className="events-content-info">
                  <h3 className="events-content-info-title">
                    {items[actualSection].name}
                  </h3>
                  {items[actualSection].info &&
                    items[actualSection].info.map((item, index) => (
                      <p className="events-content-info-text" key={index}>
                        {item}
                      </p>
                    ))}
                  {items[actualSection].gallery &&
                    items[actualSection].gallery.length > 0 && (
                      <Gallery gallery={items[actualSection].gallery} />
                    )}
                </div>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Events;
