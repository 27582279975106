import React, { useEffect, useState, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import ScrollToTop from "../../utils/scrollToTop";
import { useTranslation } from "react-i18next";

// Components
import Navbar from "../../components/Navbar/Navbar";
import MdqLogo from "../../components/MdqLogo/MdqLogo";

// Styles
import "./Chiptuning.css";

// Assets
import headerIcon from "./assets/icons/header-icon.svg";
import arrowIcon from "./assets/icons/arrow-icon.svg";
import stageDivisor from "./assets/icons/stage-divisor.svg";
import benefitsPerformanceTuning1 from "./assets/icons/performance-tuning/benefits1.svg";
import benefitsPerformanceTuning2 from "./assets/icons/performance-tuning/benefits2.svg";
import benefitsPerformanceTuning3 from "./assets/icons/performance-tuning/benefits3.svg";
import benefitsPerformanceTuning4 from "./assets/icons/performance-tuning/benefits4.svg";
import benefitsPerformanceTuning5 from "./assets/icons/performance-tuning/benefits5.svg";
import benefitsPerformanceTuning6 from "./assets/icons/performance-tuning/benefits6.svg";
import benefitsPerformanceTuning7 from "./assets/icons/performance-tuning/benefits7.svg";
import image1 from "./assets/images/1.jpg";
import image2 from "./assets/images/2.png";
import image3 from "./assets/images/3.png";
import image4 from "./assets/images/4.png";
import image5 from "./assets/images/5.png";
import benefitsEcoTuning1 from "./assets/icons/eco-tuning/benefits1.svg";
import benefitsEcoTuning2 from "./assets/icons/eco-tuning/benefits2.svg";
import benefitsEcoTuning3 from "./assets/icons/eco-tuning/benefits3.svg";
import benefitsEcoTuning4 from "./assets/icons/eco-tuning/benefits4.svg";
import benefitsEcoTuning5 from "./assets/icons/eco-tuning/benefits5.svg";
import performanceTuningStage1 from "./assets/icons/performance-tuning/stage1.svg";
import performanceTuningStage21 from "./assets/icons/performance-tuning/stage21.svg";
import performanceTuningStage22 from "./assets/icons/performance-tuning/stage22.svg";
import performanceTuningStage23 from "./assets/icons/performance-tuning/stage23.svg";
import performanceTuningStage20 from "./assets/icons/performance-tuning/stage20.svg";
import performanceTuningStage31 from "./assets/icons/performance-tuning/stage31.svg";
import performanceTuningStage32 from "./assets/icons/performance-tuning/stage32.svg";

function Chiptuning(props) {
  const [actualSection, setActualSection] = useState(0);
  const sectionsRef = useRef(null);
  const chiptuningContent = useRef(null);
  const { t } = useTranslation();

  const items = [
    {
      id: 0,
      name: t("chiptuning.items.0.name"),
      title: t("chiptuning.items.0..title"),
      image: image1,
      info: [
        {
          title: t("chiptuning.items.0.info.0.title"),
          text: [
            t("chiptuning.items.0.info.0.text.0"),
            t("chiptuning.items.0.info.0.text.1"),
          ],
        },
      ],
      benefits: [
        {
          icon: benefitsPerformanceTuning1,
          name: t("chiptuning.items.0.benefits.0.name"),
        },
        {
          icon: benefitsPerformanceTuning2,
          name: t("chiptuning.items.0.benefits.1.name"),
        },
        {
          icon: benefitsPerformanceTuning3,
          name: t("chiptuning.items.0.benefits.2.name"),
        },
        {
          icon: benefitsPerformanceTuning4,
          name: t("chiptuning.items.0.benefits.3.name"),
        },
        {
          icon: benefitsPerformanceTuning5,
          name: t("chiptuning.items.0.benefits.4.name"),
        },
        {
          icon: benefitsPerformanceTuning6,
          name: t("chiptuning.items.0.benefits.5.name"),
        },
        {
          icon: benefitsPerformanceTuning7,
          name: t("chiptuning.items.0.benefits.6.name"),
        },
      ],
    },
    {
      id: 1,
      image: image1,
      name: t("chiptuning.items.1.name"),
      info: [
        {
          title: t("chiptuning.items.1.info.0.title"),
          text: [
            t("chiptuning.items.1.info.0.text.0"),
            t("chiptuning.items.1.info.0.text.1"),
            t("chiptuning.items.1.info.0.text.2"),
            t("chiptuning.items.1.info.0.text.3"),
            t("chiptuning.items.1.info.0.text.4"),
          ],
        },
      ],
      stagesPerformanceTuning: [
        {
          title: t("chiptuning.items.1.stagesPerformanceTuning.0.title"),
          color: "rgb(220, 220, 28)",
          text: t("chiptuning.items.1.stagesPerformanceTuning.0.text"),
          icons: [performanceTuningStage1],
        },
        {
          title: t("chiptuning.items.1.stagesPerformanceTuning.1.title"),
          color: "rgb(199, 138, 25)",
          text: t("chiptuning.items.1.stagesPerformanceTuning.1.text"),
          icons: [
            performanceTuningStage21,
            performanceTuningStage22,
            performanceTuningStage23,
          ],
        },
        {
          title: t("chiptuning.items.1.stagesPerformanceTuning.2.title"),
          color: "rgb(199, 100, 25)",
          text: t("chiptuning.items.1.stagesPerformanceTuning.2.text"),
          icons: [performanceTuningStage20],
        },
        {
          title: t("chiptuning.items.1.stagesPerformanceTuning.3.title"),
          color: "red",
          text: t("chiptuning.items.1.stagesPerformanceTuning.3.text"),
          icons: [performanceTuningStage31, performanceTuningStage32],
        },
      ],
    },
    {
      id: 2,
      image: image2,
      name: t("chiptuning.items.2.name"),
      info: [
        {
          title: t("chiptuning.items.2.info.0.title"),
          text: [t("chiptuning.items.2.info.0.text.0")],
        },
      ],
      textList: [
        {
          title: t("chiptuning.items.2.textList.0.title"),
          text: [t("chiptuning.items.2.textList.0.text.0")],
        },
        {
          title: t("chiptuning.items.2.textList.1.title"),
          text: [t("chiptuning.items.2.textList.1.text.0")],
        },
      ],
      benefits: [
        {
          icon: benefitsEcoTuning1,
          name: t("chiptuning.items.2.benefits.0.name"),
        },
        {
          icon: benefitsEcoTuning2,
          name: t("chiptuning.items.2.benefits.1.name"),
        },
        {
          icon: benefitsEcoTuning3,
          name: t("chiptuning.items.2.benefits.2.name"),
        },
        {
          icon: benefitsEcoTuning4,
          name: t("chiptuning.items.2.benefits.3.name"),
        },
        {
          icon: benefitsEcoTuning5,
          name: t("chiptuning.items.2.benefits.4.name"),
        },
      ],
    },
    {
      id: 3,
      image: image3,
      name: t("chiptuning.items.3.name"),
      info: [
        {
          title: t("chiptuning.items.3.info.0.title"),
          text: [
            t("chiptuning.items.3.info.0.text.0"),
            t("chiptuning.items.3.info.0.text.1"),
            t("chiptuning.items.3.info.0.text.2"),
          ],
        },
      ],
      stagesDSG: [
        {
          title: t("chiptuning.items.3.stagesDSG.0.title"),
          items: [
            t("chiptuning.items.3.stagesDSG.0.items.0"),
            t("chiptuning.items.3.stagesDSG.0.items.1"),
            t("chiptuning.items.3.stagesDSG.0.items.2"),
            t("chiptuning.items.3.stagesDSG.0.items.3"),
            t("chiptuning.items.3.stagesDSG.0.items.4"),
            t("chiptuning.items.3.stagesDSG.0.items.5"),
          ],
        },
        {
          title: t("chiptuning.items.3.stagesDSG.1.title"),
          items: [
            t("chiptuning.items.3.stagesDSG.1.items.0"),
            t("chiptuning.items.3.stagesDSG.1.items.1"),
            t("chiptuning.items.3.stagesDSG.1.items.2"),
            t("chiptuning.items.3.stagesDSG.1.items.3"),
            t("chiptuning.items.3.stagesDSG.1.items.4"),
            t("chiptuning.items.3.stagesDSG.1.items.5"),
            t("chiptuning.items.3.stagesDSG.1.items.6"),
          ],
        },
      ],
    },
    {
      id: 4,
      image: image4,
      name: t("chiptuning.items.4.name"),
      info: [
        {
          title: t("chiptuning.items.4.info.0.title"),
          text: [
            t("chiptuning.items.4.info.0.text.0"),
            t("chiptuning.items.4.info.0.text.1"),
          ],
        },
      ],
    },
    {
      id: 5,
      image: image5,
      name: t("chiptuning.items.5.name"),
      info: [
        {
          title: t("chiptuning.items.5.info.0.title"),
          text: [t("chiptuning.items.5.info.0.text.0")],
        },
      ],
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "transparent";
    if (sectionsRef.current) {
      sectionsRef.current.style.backgroundImage = `url(${image1})`;
    }
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      {items.map((e, index) => (
        <img src={e.image} style={{ display: "none" }} key={index} alt="" />
      ))}
      <div className="topbar-overlay" />
      <Navbar />
      <section className="chiptuning">
        <div
          className="chiptuning-sections"
          ref={sectionsRef}
          style={{ backgroundImage: `url(${items[actualSection].image})` }}
        >
          <div className="chiptuning-sections-header">
            <MdqLogo />
          </div>
          <div className="chiptuning-sections-content">
            <div className="chiptuning-sections-buttons-wrapper">
              <Link
                onClick={() => {
                  chiptuningContent &&
                    (chiptuningContent.current.scrollTop = 0);
                  setActualSection(0);
                }}
                smooth
                to="#data"
                className="chiptuning-sections-button"
                style={{ marginBottom: "40px" }}
              >
                <h2
                  className="chiptuning-sections-title"
                  style={
                    actualSection === 0
                      ? {
                          color: "#ffffff",
                        }
                      : { color: "#bab4b4" }
                  }
                >
                  {t("chiptuning.items.0.name")}
                </h2>
              </Link>
              {items.map(
                (e, index) =>
                  index > 0 && (
                    <Link
                      key={index}
                      className="chiptuning-sections-button"
                      onClick={() => {
                        chiptuningContent &&
                          (chiptuningContent.current.scrollTop = 0);
                        setActualSection(index);
                      }}
                      smooth
                      to="#data"
                    >
                      <h3
                        className="chiptuning-sections-name"
                        style={
                          items[actualSection].name === e.name
                            ? {
                                color: "#ffffff",
                              }
                            : { color: "#bab4b4" }
                        }
                      >
                        {e.name}
                      </h3>
                    </Link>
                  )
              )}
            </div>
            <div className="chiptuning-sections-indicators-wrapper">
              {items.map(
                (e, index) =>
                  index > 0 && (
                    <Link
                      key={index}
                      className="chiptuning-sections-indicator"
                      onClick={() => {
                        chiptuningContent &&
                          (chiptuningContent.current.scrollTop = 0);
                        setActualSection(index);
                      }}
                      smooth
                      to="#data"
                      style={
                        items[actualSection].name === e.name
                          ? {
                              borderColor: "rgb(233, 30, 30)",
                            }
                          : { borderColor: "#bab4b4" }
                      }
                    >
                      <img
                        src={arrowIcon}
                        className="chiptuning-sections-indicator-icon"
                        style={
                          items[actualSection].name === e.name
                            ? {
                                visibility: "visible",
                              }
                            : { visibility: "hidden" }
                        }
                        alt=""
                      />
                      0{e.id}
                      <span style={{ visibility: "hidden" }}>.</span>
                    </Link>
                  )
              )}
            </div>
          </div>
        </div>
        <div className="chiptuning-content-wrapper" id="data">
          <div className="chiptuning-content" ref={chiptuningContent}>
            <div className="chiptuning-content-header">
              <img
                className="chiptuning-content-header-icon"
                src={headerIcon}
                alt=""
              />
            </div>
            {items[actualSection].info &&
              items[actualSection].info.map((e, index) => (
                <div className="chiptuning-content-info" key={index}>
                  <h3 className="chiptuning-content-info-title">{e.title}</h3>
                  {e.text.map((item, index) => (
                    <p className="chiptuning-content-info-text" key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              ))}
            {items[actualSection].textList &&
              items[actualSection].textList.map((e, index) => (
                <div className="chiptuning-content-info" key={index}>
                  <h3 className="chiptuning-content-text-list-title">
                    <img
                      src={arrowIcon}
                      className="chiptuning-content-info-arrow-icon"
                      alt=""
                    />
                    {e.title}
                  </h3>
                  {e.text.map((item, index) => (
                    <p className="chiptuning-content-info-text" key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              ))}
            {items[actualSection].benefits && (
              <div className="chiptuning-content-info">
                <h3 className="chiptuning-content-info-title">
                  {t("chiptuning.benefits")}
                </h3>
                {items[actualSection].benefits.map((item, index) => (
                  <h4
                    className="chiptuning-content-info-benefit-name"
                    key={index}
                  >
                    <img
                      src={item.icon}
                      className="chiptuning-content-info-benefit-icon"
                      alt=""
                    />
                    {item.name}
                  </h4>
                ))}
              </div>
            )}
            {items[actualSection].stagesDSG &&
              items[actualSection].stagesDSG.map((e, index) => (
                <div className="chiptuning-content-info" key={index}>
                  <h3 className="chiptuning-content-info-title">{e.title}</h3>
                  {e.items.map((e, index) => (
                    <p
                      className="chiptuning-content-stagesDSG-text"
                      key={index}
                    >
                      <img
                        src={arrowIcon}
                        className="chiptuning-content-info-arrow-icon"
                        alt=""
                      />
                      {e}
                    </p>
                  ))}
                </div>
              ))}
            {items[actualSection].stagesPerformanceTuning && (
              <div>
                <h3 className="chiptuning-content-info-title">STAGES</h3>
                {items[actualSection].stagesPerformanceTuning.map(
                  (e, index) => (
                    <div className="chiptuning-content-info" key={index}>
                      <h3 className="chiptuning-content-info-title">
                        <img
                          src={arrowIcon}
                          className="chiptuning-content-info-arrow-icon"
                          alt=""
                        />
                        <span style={{ color: e.color }}>{e.title}</span>
                        {e.icons.map((icon, index) => (
                          <div
                            className="chiptuning-content-info-stagesPerformanceTuning-icon-wrapper"
                            key={index}
                          >
                            <img
                              src={icon}
                              className="chiptuning-content-info-stagesPerformanceTuning-icon"
                              alt=""
                            />
                            {index !== e.icons.length - 1 && (
                              <img
                                src={stageDivisor}
                                className="chiptuning-content-info-stagesPerformanceTuning-divisor"
                                alt=""
                              />
                            )}
                          </div>
                        ))}
                      </h3>
                      <p className="chiptuning-content-stagesPerformanceTuning-text">
                        {e.text}
                      </p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Chiptuning;
