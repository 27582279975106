import { createAction } from "redux-actions";
import { api } from "../utils/api";
import { authHeader } from "../utils/authHeader";

export const authActions = {
  LOGIN: createAction("LOGIN"),
  LOGIN_SUCCESS: createAction("LOGIN_SUCCESS", (data) => {
    return data;
  }),
  LOGIN_ERROR: createAction("LOGIN_ERROR", (error) => {
    return {
      error,
    };
  }),
  LOGOUT: createAction("LOGOUT"),
  LOGOUT_SUCCESS: createAction("LOGOUT_SUCCESS", (data) => {
    return data;
  }),
  LOGOUT_ERROR: createAction("LOGOUT_ERROR", (error) => {
    return {
      error,
    };
  }),
  RESET_PASSWORD: createAction("RESET_PASSWORD"),
  RESET_PASSWORD_SUCCESS: createAction("RESET_PASSWORD_SUCCESS", (data) => {
    return data;
  }),
  RESET_PASSWORD_ERROR: createAction("RESET_PASSWORD_ERROR", (error) => {
    return {
      error,
    };
  }),
  UPDATE_PASSWORD: createAction("UPDATE_PASSWORD"),
  UPDATE_PASSWORD_SUCCESS: createAction("UPDATE_PASSWORD_SUCCESS", (data) => {
    return data;
  }),
  UPDATE_PASSWORD_ERROR: createAction("UPDATE_PASSWORD_ERROR", (error) => {
    return {
      error,
    };
  }),
  SIGNIN: createAction("SIGNIN"),
  SIGNIN_SUCCESS: createAction("SIGNIN_SUCCESS", (data) => {
    return data;
  }),
  SIGNIN_ERROR: createAction("SIGNIN_ERROR", (error) => {
    return {
      error,
    };
  }),
  RESET: createAction("RESET", () => {
    return null;
  }),
};

export const loginUserAction = (email, password) => (dispatch) => {
  dispatch(authActions.LOGIN());
  const params = {
    username: email,
    password: password,
  };
  api
    .get("/login", { params })
    .then((res) => {
      dispatch(authActions.LOGIN_SUCCESS(res.data));
      if (res.data.ok) {
        localStorage.setItem(
          "token_credentials",
          JSON.stringify(res.data.data.user_token)
        );
      } else {
        let lang = localStorage
          .getItem("i18nextLng")
          .split("-")[0]
          .toLowerCase();
        if (!lang || lang === "es") {
          res.data.message = "Datos de ingreso incorrectos";
        } else if (lang === "en") {
          res.data.message = "Incorrect login details";
        }
      }
    })
    .catch((error) => dispatch(authActions.LOGIN_ERROR(error)));
};

export const logoutUserAction = (history) => (dispatch) => {
  dispatch(authActions.LOGOUT());
  api
    .get("/logout/" + authHeader())
    .then((res) => {
      dispatch(authActions.LOGOUT_SUCCESS(res.data));
      reset();
      localStorage.removeItem("token_credentials");
      window.location.reload();
    })
    .catch((error) => dispatch(authActions.LOGOUT_ERROR(error)));
};

export const resetPassword = (email) => (dispatch) => {
  dispatch(authActions.RESET_PASSWORD());
  const params = {
    email: email,
  };
  api
    .get("/reset_pass/", { params })
    .then((res) => {
      let lang = localStorage.getItem("i18nextLng").split("-")[0].toLowerCase();
      if (res.data.ok) {
        if (!lang || lang === "es") {
          res.data.message = "Se envió a su email la nueva clave";
        } else if (lang === "en") {
          res.data.message = "The new password was sent to your email";
        }
      } else {
        if (
          res.data.message.toLowerCase().includes("el usuario no se encuentra")
        ) {
          if (!lang || lang === "es") {
            res.data.message =
              "El usuario no se encuentra en nuestra base de datos";
          } else if (lang === "en") {
            res.data.message = "The user is not in our database";
          }
        } else {
          if (!lang || lang === "es") {
            res.data.message = "Ocurrió un error. Vuelva a intentar más tarde";
          } else if (lang === "en") {
            res.data.message = "An error occurred. Please try again later";
          }
        }
      }
      dispatch(authActions.RESET_PASSWORD_SUCCESS(res.data));
    })
    .catch((error) => dispatch(authActions.RESET_PASSWORD_ERROR(error)));
};

export const updatePassword = (password) => (dispatch) => {
  dispatch(authActions.UPDATE_PASSWORD());
  api
    .get("/update_pass/" + authHeader() + "/" + password)
    .then((res) => {
      if (res.data.ok) {
        let lang = localStorage
          .getItem("i18nextLng")
          .split("-")[0]
          .toLowerCase();
        if (!lang || lang === "es") {
          res.data.message = "Clave cambiada con éxito";
        } else if (lang === "en") {
          res.data.message = "Password changed successfully";
        }
      }
      dispatch(authActions.UPDATE_PASSWORD_SUCCESS(res.data));
    })
    .catch((error) => dispatch(authActions.UPDATE_PASSWORD_ERROR(error)));
};

export const signinUserAction = (email, name, lastname, phone) => (
  dispatch
) => {
  dispatch(authActions.SIGNIN());
  var FormData = require("form-data");
  let form = new FormData();
  form.append("client_email", email);
  form.append("client_name", name);
  form.append("client_lastname", lastname);
  form.append("client_phone", phone);
  form.append("client_device_id", Date.now());
  api
    .post("/new_client", form)
    .then((res) => {
      let lang = localStorage.getItem("i18nextLng").split("-")[0].toLowerCase();
      if (res.data.ok) {
        if (!lang || lang === "es") {
          res.data.message =
            "Registro exitoso. Se envió a su email la clave para ingresar";
        } else if (lang === "en") {
          res.data.message =
            "Successful registration. The password was sent to your email";
        }
      } else {
        if (
          res.data.message.toLowerCase().includes("el usuario ya se encuentra")
        ) {
          if (!lang || lang === "es") {
            res.data.message =
              "El usuario ya se encuentra en nuestra base de datos";
          } else if (lang === "en") {
            res.data.message = "The user is already in our database";
          }
        } else {
          if (!lang || lang === "es") {
            res.data.message = "Ocurrió un error. Vuelva a intentar más tarde";
          } else if (lang === "en") {
            res.data.message = "An error occurred. Please try again later";
          }
        }
      }
      dispatch(authActions.SIGNIN_SUCCESS(res.data));
    })
    .catch((error) => dispatch(authActions.SIGNIN_ERROR(error)));
};

export const reset = () => (dispatch) => {
  dispatch(authActions.RESET());
};
