import { handleActions } from "redux-actions";

export const vehiclesTypesReducer = handleActions(
  {
    GET_TYPES: (state, action) => {
      return {
        ...state,
        vehiclesTypesError: null,
        fetching: true,
      };
    },
    GET_TYPES_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    GET_TYPES_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        vehiclesTypesError: action,
      };
    },
  },
  { fetching: false, vehiclesTypesError: null, data: null }
);
