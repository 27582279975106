import { createAction } from "redux-actions";
import { api } from "../utils/api";
import { authHeader } from "../utils/authHeader";

export const contactActions = {
  CONTACT: createAction("CONTACT"),
  CONTACT_SUCCESS: createAction("CONTACT_SUCCESS", (data) => {
    return data;
  }),
  CONTACT_ERROR: createAction("CONTACT_ERROR", (error) => {
    return {
      error,
    };
  }),
  RESELLER: createAction("RESELLER"),
  RESELLER_SUCCESS: createAction("RESELLER_SUCCESS", (data) => {
    return data;
  }),
  RESELLER_ERROR: createAction("RESELLER_ERROR", (error) => {
    return {
      error,
    };
  }),
  RESET: createAction("RESET", () => {
    return null;
  }),
};

export const contactAction = (message) => (dispatch) => {
  dispatch(contactActions.CONTACT());
  var FormData = require("form-data");
  let contactMessage = new FormData();
  contactMessage.append("message", message);
  api
    .post("/contact/" + authHeader(), contactMessage)
    .then((res) => {
      if (res.data.ok) {
        let lang = localStorage
          .getItem("i18nextLng")
          .split("-")[0]
          .toLowerCase();
        if (!lang || lang === "es") {
          res.data.message = "Formulario de contacto enviado correctamente";
        } else if (lang === "en") {
          res.data.message = "Contact form sent correctly";
        }
      }
      dispatch(contactActions.CONTACT_SUCCESS(res.data));
    })
    .catch((error) => dispatch(contactActions.CONTACT_ERROR(error)));
};

export const resellerAction = (data) => (dispatch) => {
  dispatch(contactActions.RESELLER());
  var FormData = require("form-data");
  let resellerContactData = new FormData();
  resellerContactData.append("client-name", data.name);
  resellerContactData.append("client-lastname", data.lastname);
  resellerContactData.append("client-email", data.email);
  resellerContactData.append("client-tel", data.phone);
  resellerContactData.append("client-country", data.country);
  resellerContactData.append("client-province", data.province);
  resellerContactData.append("client-city", data.city);
  resellerContactData.append("g-recaptcha-response", data.gRecaptcha);

  api
    .post("/wanna_be_reseller", resellerContactData)
    .then((res) => {
      let lang = localStorage.getItem("i18nextLng").split("-")[0].toLowerCase();
      if (res.data.ok) {
        if (!lang || lang === "es") {
          res.data.message = "Formulario enviado correctamente";
        } else if (lang === "en") {
          res.data.message = "Form sent correctly";
        }
      } else {
        if (res.data.message.toLowerCase().includes("error de verificación")) {
          if (!lang || lang === "es") {
            res.data.message = "Error de verificación de CAPTCHA";
          } else if (lang === "en") {
            res.data.message = "CAPTCHA verification error";
          }
        } else {
          if (!lang || lang === "es") {
            res.data.message = "Ocurrió un error. Vuelva a intentar más tarde";
          } else if (lang === "en") {
            res.data.message = "An error occurred. Please try again later";
          }
        }
      }
      dispatch(contactActions.RESELLER_SUCCESS(res.data));
    })
    .catch((error) => dispatch(contactActions.RESELLER_ERROR(error)));
};

export const reset = () => (dispatch) => {
  dispatch(contactActions.RESET());
};
