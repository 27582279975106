import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import * as smoothscroll from "smoothscroll-polyfill";
import smoothscrollPolyfill from "smoothscroll-polyfill";
import { CircularProgress } from "@material-ui/core";
import "./utils/i18n";

// App
import App from "./App";

// Styles
import "./normalize.css";
import "./index.css";

smoothscrollPolyfill.polyfill();
smoothscroll.polyfill();

export const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense
        fallback={
          <div className="loading-container">
            <CircularProgress />
          </div>
        }
      >
        <Router basename="/public">
          <App />
        </Router>
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
