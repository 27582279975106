import React from "react";
import { NavLink } from "react-router-dom";

// Styles
import "./Sidebar.css";

const Sidebar = (props) => {
  return (
    <div
      className={
        props.section !== "7" ? "home-sidebar" : "home-sidebar sidebar-complete"
      }
    >
      <NavLink
        exact
        to="/home/1"
        activeStyle={{
          backgroundColor: "rgb(204, 16, 16)",
        }}
        className="home-sidebar-link"
      ></NavLink>
      <NavLink
        exact
        to="/home/2"
        activeStyle={{
          backgroundColor: "rgb(204, 16, 16)",
        }}
        className="home-sidebar-link"
      ></NavLink>
      <NavLink
        exact
        to="/home/3"
        activeStyle={{
          backgroundColor: "rgb(204, 16, 16)",
        }}
        className="home-sidebar-link"
      ></NavLink>
      <NavLink
        exact
        to="/home/4"
        activeStyle={{
          backgroundColor: "rgb(204, 16, 16)",
        }}
        className="home-sidebar-link"
      ></NavLink>
      <NavLink
        exact
        to="/home/5"
        activeStyle={{
          backgroundColor: "rgb(204, 16, 16)",
        }}
        className="home-sidebar-link"
      ></NavLink>
      <NavLink
        exact
        to="/home/6"
        activeStyle={{
          backgroundColor: "rgb(204, 16, 16)",
        }}
        className="home-sidebar-link"
      ></NavLink>
      <NavLink
        exact
        to="/home/7"
        className="home-sidebar-link link-bottom"
      ></NavLink>
      <p className="home-sidebar-section-actual">0{props.section}</p>
      <p className="home-sidebar-section-total">07</p>
    </div>
  );
};

export default Sidebar;
