import React, { useEffect, useRef } from "react";

// Styles
import "./TeamCard.css";

// Assets
import teamCardLogo from "./assets/icons/mdq-card.svg";
import iconCard from "./assets/icons/icon-card.svg";
import contactIcon from "./assets/icons/contact-card.svg";

function TeamCard(props) {
  const teamCardRef = useRef(null);
  const contactRef = useRef(null);

  const showContactInfo = () => {
    if (contactRef) {
      contactRef.current.style.visibility = "visible";
    }
  };

  const hideContactInfo = () => {
    if (contactRef) {
      contactRef.current.style.visibility = "hidden";
    }
  };

  useEffect(() => {
    if (teamCardRef && (props.member.tel || props.member.email)) {
      teamCardRef.current.addEventListener("mouseover", () =>
        showContactInfo()
      );
      teamCardRef.current.addEventListener("mouseleave", () =>
        hideContactInfo()
      );
    }
  }, [props.member]);

  return (
    <div className="team-card" ref={teamCardRef}>
      <div className="team-card-header">
        <div className="team-card-header-content">
          <img src={teamCardLogo} className="mdq-logo-card" alt="" />
          <span className="mdq-logo-card-text">Team</span>
        </div>
        <img src={iconCard} className="mdq-icon-card" alt="" />
      </div>
      <div className="team-card-image-wrapper">
        <div
          className="team-card-image-wrapper-circle"
          style={{ backgroundColor: props.color }}
        />
        <div className="team-card-image-wrapper-ring" />
        <img
          src={props.member.image}
          className="team-card-image-member"
          alt=""
        />
      </div>
      <div className="team-card-data">
        <h3 className="team-card-data-name">{props.member.name}</h3>
        <p className="team-card-data-position">{props.member.position}</p>
      </div>
      <div className="team-card-contact" ref={contactRef}>
        <img src={contactIcon} className="team-card-contact-icon" alt="" />
        <p className="team-card-contact-text">{props.member.tel}</p>
        <p className="team-card-contact-text">{props.member.email}</p>
      </div>
    </div>
  );
}

export default TeamCard;
