import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../../../../utils/scrollToTop";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

// Components
import Navbar from "../../../../components/Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import MdqLogo from "../../../../components/MdqLogo/MdqLogo";
import AlientechLogo from "../../../../components/AlientechLogo/AlientechLogo";

// Styles
import "../../Home.css";
import "./Home04.css";

// Assets
import image1 from "./assets/1.jpg";
import googlePlay from "./assets/googlePlay.png";
import appStore from "./assets/appStore.png";
import mobile from "./assets/mobile.png";
import app from "./assets/app.mp4";
import poster from "./assets/poster.png";
import arrow from "../../../../assets/icons/arrow.svg";

function Home04() {
  const desktopVideoRef = useRef(null);
  const { t } = useTranslation();

  const onDesktopVideoEnded = () => {
    desktopVideoRef.current.currentTime = 0;
    desktopVideoRef.current.play();
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${image1})`;
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar />
      <Sidebar section={"4"} />
      <MdqLogo style={{ position: "absolute" }} />
      <div className="home-slider-container home-slider-download">
        <section className="home-section-flex">
          <div className="home-download-container">
            <div className="home-slider-container-col download-col1">
              <h2 className="home-download-title">
                {t("home04.title1")}
                <br />
                {t("home04.title2")}
              </h2>
              <div className="download-container">
                <div className="download-item-container">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mdqracing.app&hl=es_AR"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googlePlay}
                      className="home-download-image"
                      alt="Google Play"
                    />
                  </a>
                </div>
                <div className="download-item-container">
                  <a
                    href="https://apps.apple.com/us/app/mdqracing/id1422058913"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appStore}
                      className="home-download-image"
                      alt="App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="home-slider-container-col">
              <div className="home-mobile-container">
                <img src={mobile} className="home-mobile" alt="mobile" />
                {app &&
                  (isMobile ? (
                    <video
                      poster={poster}
                      className="home-mobile-video"
                      muted
                      controls
                      playsInline
                      id="videoId"
                    >
                      <source
                        className="home-mobile-video-source"
                        src={app}
                        type="video/mp4"
                      ></source>
                      I'm sorry; your browser doesn't support HTML5 video.
                    </video>
                  ) : (
                    <video
                      ref={desktopVideoRef}
                      poster={poster}
                      className="home-mobile-video"
                      muted
                      autoPlay
                      playsInline
                      onEnded={onDesktopVideoEnded}
                      id="videoId"
                    >
                      <source
                        className="home-mobile-video-source"
                        src={app}
                        type="video/mp4"
                      ></source>
                      I'm sorry; your browser doesn't support HTML5 video.
                    </video>
                  ))}
              </div>
            </div>
            <div className="home-slider-container-col home-download-subtitle-container">
              <h2 className="home-download-subtitle">
                {t("home04.subtitle1")} <br /> {t("home04.subtitle2")}
              </h2>
            </div>
          </div>
        </section>
        <div className="home-slider-container-row2">
          <div className="home-slider-container-row2-cl-start">
            <AlientechLogo />
          </div>
          <div className="home-slider-container-row2-cl-center">
            <NavLink className="arrow-down-button" to="/home/5">
              <img src={arrow} alt="" className="arrow-down" />
            </NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home04;
