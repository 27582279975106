import { handleActions } from "redux-actions";

export const lastServicesReducer = handleActions(
  {
    GET_ALL_LAST_SERVICES: (state, action) => {
      return {
        ...state,
        lastServicesError: null,
        fetching: true,
      };
    },
    GET_ALL_LAST_SERVICES_SUCCESS: (state, action) => {
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    },
    GET_ALL_LAST_SERVICES_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        lastServicesError: action,
      };
    },
  },
  { fetching: false, lastServicesError: null, data: null }
);
