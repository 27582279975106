import { handleActions } from "redux-actions";

export const contactReducer = handleActions(
  {
    CONTACT: (state, action) => {
      return {
        ...state,
        contactError: null,
        fetchingContact: true,
      };
    },
    CONTACT_SUCCESS: (state, action) => {
      return {
        ...state,
        fetchingContact: false,
        contactRes: action.payload,
      };
    },
    CONTACT_ERROR: (state, action) => {
      return {
        ...state,
        fetchingContact: false,
        contactError: action,
      };
    },
    RESELLER: (state, action) => {
      return {
        ...state,
        resellerError: null,
        fetchingReseller: true,
      };
    },
    RESELLER_SUCCESS: (state, action) => {
      return {
        ...state,
        fetchingReseller: false,
        resellerRes: action.payload,
      };
    },
    RESELLER_ERROR: (state, action) => {
      return {
        ...state,
        fetchingReseller: false,
        resellerError: action,
      };
    },
    RESET: (state, action) => {
      return {
        fetchingContact: false,
        contactRes: action.payload,
        contactError: null,
        fetchingReseller: false,
        resellerRes: action.payload,
        resellerError: null,
      };
    },
  },
  {
    fetchingContact: false,
    contactRes: null,
    contactError: null,
    fetchingReseller: false,
    resellerRes: null,
    resellerError: null,
  }
);
