import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../../../../utils/scrollToTop";
import { useTranslation } from "react-i18next";
import { Facebook, YouTube, Instagram } from "@material-ui/icons";

// Components
import Navbar from "../../../../components/Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

// Styles
import "../../Home.css";
import "./Home07.css";

// Assets
import divider from "./assets/divider.svg";
import icon1 from "./assets/icon1.svg";
import icon2 from "./assets/icon2.svg";
import arrow from "../../../../assets/icons/arrow.svg";
import logo from "./assets/logo.svg";

function Home07() {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "rgb(4, 4, 4)";
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="topbar-overlay" />
      <Navbar section={"7"} />
      <Sidebar section={"7"} />
      <section className="home-footer-section">
        <div className="home-footer-section-row1">
          <NavLink to="home/01" className="home-footer-back">
            <img src={arrow} alt="back" className="home-footer-back-image" />
          </NavLink>
        </div>
        <div className="home-footer-section-row2">
          <img src={logo} alt="logo" className="footer-logo" />
        </div>
        <div className="home-footer-section-row3">
          <h2 className="home-footer-row3-title">{t("home07.title")}</h2>
        </div>
        <div className="home-footer-section-row4">
          <div className="home-footer-row4-contact">
            <div className="home-footer-row4-cl">
              <p className="home-footer-row4-title">
                {t("home07.footer1.title")}
              </p>
              <p className="home-footer-row4-text">
                {t("home07.footer1.data")}
              </p>
              <div className="home-footer-row4-social">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/mdqracing"
                >
                  <Facebook className="home-footer-row4-social-icon" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/user/mdqracing"
                >
                  <YouTube className="home-footer-row4-social-icon" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/mdqracing"
                >
                  <Instagram className="home-footer-row4-social-icon" />
                </a>
              </div>
            </div>
            <img className="home-footer-row4-icon" src={icon1} alt="" />
          </div>
          <div className="home-footer-row4-contact contact-button">
            <img src={divider} className="home-footer-row4-divider" alt="" />
            <div className="home-footer-row4-button">
              <NavLink className="default-button" to="/reseller">
                <span>{t("home07.reseller-button")}</span>
              </NavLink>
              <div className="home-footer-row4-copy">
                <p className="home-footer-row4-copy-text">
                  {t("home07.copyright1")}
                  <br />
                  {t("home07.copyright2")}
                </p>
              </div>
              <div className="home-footer-row4-cookies">
                <p className="home-footer-row4-cookies-text">
                  {t("home07.cookies")}
                </p>
              </div>
            </div>
            <img src={divider} className="home-footer-row4-divider" alt="" />
          </div>
          <div className="home-footer-row4-contact">
            <img className="home-footer-row4-icon" src={icon2} alt="" />
            <div className="home-footer-row4-cl">
              <p className="home-footer-row4-title">
                {t("home07.footer2.title")}
              </p>
              <p className="home-footer-row4-text">
                {t("home07.footer2.address")}
              </p>
              <p className="home-footer-row4-text">
                {t("home07.footer2.phone1")}
              </p>
              <p className="home-footer-row4-text">
                {t("home07.footer2.phone2")}
              </p>
              <p className="home-footer-row4-text">
                {t("home07.footer2.email")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Home07;
