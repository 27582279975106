import React from "react";

// Styles
import "./VehicleCard.css";

// Assets
import iconCard from "./assets/icons/icon-card.svg";
import icon1 from "./assets/icons/icon1.svg";
import icon2 from "./assets/icons/icon2.svg";

function VehicleCard(props) {
  return (
    <div className="vehicle-card">
      <div className="vehicle-card-header">
        <img src={iconCard} className="vehicle-card-icon" alt="" />
        <h3 className="vehicle-card-header-title">
          {props.vehicle.band_name + " " + props.vehicle.model_name}
        </h3>
        <div className="vehicle-card-data-text-container">
          <img className="vehicle-card-data-text-icon" src={icon1} alt="" />
          <p className="vehicle-card-data-text">{props.vehicle.engine_name}</p>
        </div>
        {props.vehicle.ecu_brand && (
          <div className="vehicle-card-data-text-container">
            <img className="vehicle-card-data-text-icon" src={icon2} alt="" />
            <p className="vehicle-card-data-text">
              {props.vehicle.ecu_brand + " " + props.vehicle.ecu_version}
            </p>
          </div>
        )}
      </div>
      <div className="vehicle-card-image-container">
        {props.vehicle.vehicle_photo && (
          <img
            className="vehicle-card-image"
            src={props.vehicle.vehicle_photo}
            alt=""
          />
        )}
      </div>
    </div>
  );
}

export default VehicleCard;
